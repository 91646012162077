/* eslint-disable react/no-array-index-key */
import React from 'react';
import uuid from 'react-uuid';
import PropTypes from 'prop-types';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import QuestionText from '../QuestionText';
import CorrectIcon from '../../../../../../tap-fe-components/src/components/Icons/Correct';
import IncorrectIcon from '../../../../../../tap-fe-components/src/components/Icons/Incorrect';
import {
    OptionText, ChoiceRadio, RadioControlLabel, OptionHeaderText, ChoiceOption,
    ChoiceControl, OptionHeader, ChoiceText, ChoiceImage, SingleQuestionWrapper, SingleChoiceQuestionWrapper,
    ChoiceRadioGroup, RadioControlLabelWrapper, Answerlabel, NoGuidanceWrapper,
    ChoiceOptionsWrapper, QuestionContainer, LeftSideWrapper, RightSideWrapper, StyledFormGroup,
    ScrollBox, TickIconWrapper, TtsOnlyDiv,
} from './index.styles';
import StringTable from '../../../services/stringTable';
import {
    IMG, ALT, IMAGETEXT, OPTIONTEXT,
} from '../../../services/stringConstants';
import { getHyphenatedText } from '../../../services/helpers';
import keys from '../../../constants/keys';


const correctAnswerText = StringTable.getString(keys.questions, keys.common, keys.correctAnswer);
const rubricGuidanceText = StringTable.getString(keys.questions, keys.common, keys.rubricGuidanceText);
const noGuidanceOrRubric = StringTable.getString(keys.questions, keys.common, keys.noGuidanceOrRubric);
class ChoiceMatrix extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            response: props.response,
            id: uuid(),
            headerHeight: 0,
            itemsHeight: [],
        };
        this.handleChange = this.handleChange.bind(this);
        this.getResponseData = props.getResponseData.bind(this);
        this.optionHeader = React.createRef();
        this.containerRef = React.createRef();
        this.scrollbarRef = React.createRef();
        this.itemsRef = [];
    }

    componentDidMount() {
        const {
            isPreview,
            showGrading,
            correctResponse,
            showCorrectAnswer,
        } = this.props;

        const showQuestionPreview = (showCorrectAnswer && correctResponse) || showGrading ||
            (isPreview && !showCorrectAnswer);

        if (showQuestionPreview) {
            window.addEventListener('resize', this.handleResize);
            this.scrollbarRef.current.addEventListener('scroll', this.setShadows);
            this.handleResize();
            this.setShadows();
        }
    }

    componentWillUnmount() {
        const {
            isPreview,
            showGrading,
            correctResponse,
            showCorrectAnswer,
        } = this.props;

        const showQuestionPreview = (showCorrectAnswer && correctResponse) || showGrading ||
            (isPreview && !showCorrectAnswer);

        if (showQuestionPreview) {
            window.removeEventListener('resize', this.handleResize);
            this.scrollbarRef.current.removeEventListener('scroll', this.setShadows);
        }
    }

    handleChange = (event, key, targetValue) => {
        const { value = targetValue } = event.target;
        const { responseIdentifier } = this.props;
        const miniResponse = {
            xmlIdentifier: responseIdentifier,
            responseText: `${key} ${value}`,
        };
        const { response } = this.state;
        let miniResponseIndex = response.findIndex(({ responseText }) => responseText.startsWith(key));
        miniResponseIndex = miniResponseIndex === -1 ? response.length : miniResponseIndex;
        const newResponse = [
            ...response.slice(0, miniResponseIndex),
            miniResponse,
            ...response.slice(miniResponseIndex + 1),
        ];
        this.setState({ response: newResponse }, () => {
            const allEmpty = newResponse.every(singleResponse => singleResponse.responseText === '');
            this.getResponseData({ isAttempted: !allEmpty, response: newResponse });
            if (targetValue) {
                this.forceUpdate();
            }
        });
    };

    getResponseMapping = () => {
        const { questions, choices } = this.props;
        const { response } = this.state;
        const checkedResponseMap = {};
        response.forEach(({ responseText }) => {
            const currentChoice = choices?.find(choice => responseText.endsWith(choice
                .getAttribute('identifier')));
            const currentQuestion = questions?.find(question => responseText.startsWith(question
                .getAttribute('identifier')));
            checkedResponseMap[currentQuestion?.getAttribute('identifier')] =
                currentChoice?.getAttribute('identifier');
        });
        return checkedResponseMap;
    }

    handleResize = () => {
        this.setState({
            headerHeight: this.optionHeader.current?.offsetHeight,
            itemsHeight: this.itemsRef.map(item => item.offsetHeight || 42),
        }, () => this.setShadows());
    }

    setShadows = () => {
        const scrollRef = this.scrollbarRef.current;
        const containerRef = this.containerRef.current;
        const isScrolling = scrollRef.scrollWidth > scrollRef.clientWidth;
        containerRef.classList.toggle('off-left', isScrolling && scrollRef.scrollLeft > 0);
        containerRef.classList.toggle('off-right', isScrolling &&
            scrollRef.scrollLeft < (scrollRef.scrollWidth - scrollRef.offsetWidth));
    }

    checkNodeNameP = (node, index) => {
        let { attribs: { style } } = node;
        style = `${style}; flex-basis: 100%;`;
        const newNode = node;
        newNode.attribs.style = style;

        return convertNodeToElement(newNode, index, this.transform);
    }

    checkNodeNameImg = (node) => {
        const { isQuestionContainer } = this.props;
        const {
            src, alt, height, width,
        } = node.attribs;
        const updatedHeight = height && parseFloat(height);
        const updatedWidth = width && parseFloat(width);
        const imageHeight = height && updatedHeight < 360 ? updatedHeight : 360;
        const imageWidth = width && updatedWidth < 376 ? updatedWidth : 376;
        const imageProps = {
            src,
            alt,
            width: width ? imageWidth : undefined,
            height: height ? imageHeight : undefined,
        };
        return (
            <ChoiceImage
                className={isQuestionContainer ? 'question-container' : null}
                {...imageProps}
                onLoad={this.handleResize}
            />
        );
    }

    transform = (node, index) => {
        if (node.type === 'tag') {
            if (node.name === 'p') {
                return this.checkNodeNameP(node, index);
            }
            if (node.name === 'img') {
                return this.checkNodeNameImg(node);
            }
            return null;
        }
        if (node.data.trim() === '' && node.parent === null) {
            return null;
        }
        if (node.type === 'text') {
            if (node?.parent?.name !== 'p') {
                return (
                    <p key={uuid()} style={{ flexBasis: '100%' }}>
                        {convertNodeToElement(node, index, this.transform)}
                    </p>
                );
            }
            return undefined;
        }
        return null;
    }

    getShowQuestionPreview = () => {
        const {
            showCorrectAnswer, showGrading, correctResponse, isPreview,
        } = this.props;
        return (showCorrectAnswer && correctResponse) || showGrading ||
        (isPreview && !showCorrectAnswer);
    }

    singleChoiceQuestionWrapper = (choice, index) => {
        const {
            correctOptions, choices, showCorrectAnswer, showGrading, correctResponse,
            isNotAttempted, disable,
        } = this.props;
        const { id, itemsHeight } = this.state;
        const hasTickMarks = (showCorrectAnswer && correctResponse) ||
            (showGrading && correctResponse);
        const checkedResponseMap = this.getResponseMapping();
        const disabledChoice = showCorrectAnswer || showGrading || disable;

        return (
            <SingleChoiceQuestionWrapper
                key={`choice-matrix-group-${id}-${index}`}
                onChange={(event) => {
                    this.handleChange(event, choice.getAttribute('identifier'));
                }}
            >
                <ChoiceRadioGroup
                    aria-label={this.getRadioAriaLabel(choice)}
                    role="group"
                    value={checkedResponseMap[choice.getAttribute('identifier')] || -1}
                >
                    <ChoiceOptionsWrapper hasTickMarks={hasTickMarks}>
                        {choices.map((option, optionIndex) => {
                            const groupValue = checkedResponseMap[
                                choice.getAttribute('identifier')
                            ];
                            const currentValue = option.getAttribute('identifier');
                            const isActive = groupValue === currentValue;
                            let radioIndex = -1;
                            radioIndex = optionIndex === 0 ? 0 : -1;
                            radioIndex = isActive ? 0 : radioIndex;
                            const showTickInGrading = showGrading && correctResponse && !isNotAttempted;
                            const hasTick = (showCorrectAnswer && correctResponse) ||
                                showTickInGrading;
                            return (
                                <ChoiceOption
                                    key={
                                        `choice-matrix-${id}-${index}-${optionIndex}`
                                    }
                                    hasTickMarks={hasTickMarks}
                                    onClick={event => this.onOptionChange(event, choice, currentValue)}
                                    height={itemsHeight[index] > 42 ?
                                        itemsHeight[index] - 42 : 0}
                                    disabled={disabledChoice}

                                >
                                    <RadioControlLabelWrapper hasTick={hasTick}>
                                        <RadioControlLabel
                                            value={currentValue}
                                            control={(
                                                <ChoiceRadio
                                                    tabIndex={radioIndex}
                                                    size="small"
                                                />
                                            )}
                                            aria-label={option?.textContent || ''}
                                            hasTick={hasTick}
                                        />

                                        {((showCorrectAnswer && correctResponse) ||
                                        (showTickInGrading)) &&
                                        correctOptions[choice
                                            .getAttribute('identifier')] ===
                                            currentValue ?
                                            (
                                                <TickIconWrapper>
                                                    <CorrectIcon size={24} />
                                                </TickIconWrapper>
                                            ) : ''
                                        }
                                        {showTickInGrading &&
                                        (correctOptions[choice
                                            .getAttribute('identifier')] !==
                                            currentValue && currentValue ===
                                            checkedResponseMap[choice
                                                .getAttribute('identifier')]
                                        ) ?
                                            (
                                                <TickIconWrapper>
                                                    <IncorrectIcon size={24} />
                                                </TickIconWrapper>
                                            ) : ''
                                        }
                                    </RadioControlLabelWrapper>
                                </ChoiceOption>
                            );
                        })}
                    </ChoiceOptionsWrapper>
                </ChoiceRadioGroup>
            </SingleChoiceQuestionWrapper>
        );
    }

    questionContainer = () => {
        const {
            questions, choices, showCorrectAnswer, showGrading, correctResponse, isQuestionContainer,
        } = this.props;
        const { id, headerHeight } = this.state;
        const hasTickMarks = (showCorrectAnswer && correctResponse) ||
            (showGrading && correctResponse);
        const leftSideWrapperClassName = isQuestionContainer ? 'question-container' : null;
        const totalOption = choices.length;

        return (
            <QuestionContainer>
                <LeftSideWrapper
                    className={leftSideWrapperClassName}
                    marginTop={`${headerHeight}px`}
                >
                    {questions.map((choice, index) => (
                        <SingleQuestionWrapper
                            key={`choice-question-${id}-${index}`}
                            tabIndex={totalOption === 4 ? '0' : '-1'}
                        >
                            <ChoiceText
                                ref={(r) => {
                                    this.itemsRef[index] = r;
                                }}
                                className={isQuestionContainer ? 'question-container' : null}
                            >
                                {ReactHtmlParser(choice.innerHTML, {
                                    decodeEntities: true,
                                    transform: this.transform,
                                })}
                                <TtsOnlyDiv>
                                    {
                                        choices.map((option, optionIndex) => (
                                            `${OPTIONTEXT} ${optionIndex + 1} ${option?.textContent || ''}.`
                                        ))
                                    }
                                </TtsOnlyDiv>
                            </ChoiceText>
                        </SingleQuestionWrapper>
                    ))}
                </LeftSideWrapper>
                <RightSideWrapper
                    id={`container-${uuid()}`}
                    className="container rs_skip"
                    ref={this.containerRef}
                >
                    <ScrollBox
                        className="scrollbox"
                        ref={this.scrollbarRef}
                        tabIndex={0}
                    >
                        <OptionHeader ref={this.optionHeader}>
                            <OptionHeaderText>
                                {choices.map((option, index) => (
                                    <OptionText
                                        key={`choice-matrix-text-${id}-${index}`}
                                        hasTickMarks={hasTickMarks}
                                    >
                                        {this.getOptionText(option)}
                                    </OptionText>
                                ))}
                            </OptionHeaderText>
                        </OptionHeader>
                        {questions.map((choice, index) => this.singleChoiceQuestionWrapper(choice, index))}
                    </ScrollBox>
                    <div className="shadow shadow-left" aria-hidden="true" />
                    <div className="shadow shadow-right" aria-hidden="true" />
                </RightSideWrapper>
            </QuestionContainer>
        );
    }

    getOptionText = (option) => {
        const word = option.textContent;
        const optionText = getHyphenatedText(word, 12, true);
        return (
            <React.Fragment>
                {optionText.map(seg => `${seg}\n`)}
            </React.Fragment>
        );
    }

    getRadioAriaLabel = (choice) => {
        let label = choice?.textContent;
        if (choice?.children?.length === 1 && choice?.children[0].tagName === IMG) {
            label = choice?.children[0].getAttribute(ALT);
        }
        return label || '';
    }

    getRadioTextValue = (choice) => {
        let label = choice?.textContent;
        if (choice?.children.length === 1 && choice?.children[0].tagName === IMG) {
            label = `${IMAGETEXT} ${choice?.children[0].getAttribute(ALT)}`;
        }
        if (choice?.children.length > 1 && choice?.children[0].tagName === IMG) {
            label = `${IMAGETEXT} ${choice?.children[0].getAttribute(ALT)} ${choice?.textContent}`;
        }
        return label || '';
    }

    onOptionChange = (event, choice, currentValue) => {
        this.handleChange(
            event, choice.getAttribute('identifier'),
            currentValue,
        );
    }

    getGuidanceComponent = () => {
        const { isRubric, isAuthorView, isQuestionWrapper } = this.props;
        const guidanceComponent = (isRubric ? (<NoGuidanceWrapper>{rubricGuidanceText}</NoGuidanceWrapper>) :
            (isAuthorView && isQuestionWrapper && <NoGuidanceWrapper>{noGuidanceOrRubric}</NoGuidanceWrapper>));
        return guidanceComponent;
    }

    getIsOtherTag = (questionHTML) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(questionHTML, 'text/html');
        const questionDiv = doc.querySelector('.question');
        if (questionDiv?.children?.length > 0 &&
            questionDiv.children[questionDiv.children.length - 1].tagName !== 'P') {
            return true;
        }
        return false;
    }

    render() {
        const {
            questionHTML, showCorrectAnswer,
            showGrading, userAnswerLabel,
            disable, isTtsEnabled, isAnswerPreviewView,
            questionpartPrefix,
            isRubric, isAuthorView, isQuestionWrapper,
        } = this.props;
        const showQuestionPreview = this.getShowQuestionPreview();
        const disabledChoice = showCorrectAnswer || showGrading || disable;
        let isBottomPadding = true;
        let isOtherTag = false;
        if (showCorrectAnswer) {
            isBottomPadding = showQuestionPreview || isRubric || (isAuthorView && isQuestionWrapper);
            isOtherTag = !isBottomPadding && this.getIsOtherTag(questionHTML);
        }
        return (
            <React.Fragment>
                <ChoiceControl
                    component="fieldset"
                    disabled={disabledChoice}
                >
                    <StyledFormGroup>
                        {questionHTML && !isAnswerPreviewView && (
                            <QuestionText
                                questionHTML={questionHTML}
                                isTtsEnabled={isTtsEnabled}
                                questionpartPrefix={questionpartPrefix}
                                isBottomPadding={isBottomPadding}
                                isOtherTag={isOtherTag}
                            />
                        )}
                        {showCorrectAnswer && showQuestionPreview ?
                            <Answerlabel notCapitalize>{correctAnswerText}</Answerlabel> : null}
                        {showGrading ? <Answerlabel>{userAnswerLabel}</Answerlabel> : null}
                        {
                            showQuestionPreview ? this.questionContainer() : (
                                this.getGuidanceComponent()
                            )
                        }
                    </StyledFormGroup>
                </ChoiceControl>
            </React.Fragment>
        );
    }
}

ChoiceMatrix.propTypes = {
    questionHTML: PropTypes.string.isRequired,
    questions: PropTypes.arrayOf(PropTypes.any).isRequired,
    choices: PropTypes.arrayOf(PropTypes.any).isRequired,
    getResponseData: PropTypes.func,
    showCorrectAnswer: PropTypes.bool,
    showGrading: PropTypes.bool,
    responseIdentifier: PropTypes.string,
    response: PropTypes.arrayOf(PropTypes.shape({
        xmlIdentifier: PropTypes.string,
        responseText: PropTypes.string,
    })),
    correctOptions: PropTypes.objectOf(PropTypes.string),
    userAnswerLabel: PropTypes.string,
    disable: PropTypes.bool,
    correctResponse: PropTypes.bool,
    isPreview: PropTypes.bool,
    isQuestionContainer: PropTypes.bool,
    isTtsEnabled: PropTypes.bool,
    isNotAttempted: PropTypes.bool,
    isAnswerPreviewView: PropTypes.bool,
    questionpartPrefix: PropTypes.string,
    isRubric: PropTypes.bool,
    isAuthorView: PropTypes.bool,
    isQuestionWrapper: PropTypes.bool,
};

ChoiceMatrix.defaultProps = {
    getResponseData: () => { /* Return Empty */ },
    responseIdentifier: '',
    response: [],
    showCorrectAnswer: false,
    showGrading: false,
    correctOptions: {},
    userAnswerLabel: '',
    disable: false,
    correctResponse: false,
    isPreview: false,
    isQuestionContainer: false,
    isTtsEnabled: false,
    isNotAttempted: false,
    isAnswerPreviewView: false,
    questionpartPrefix: '',
    isRubric: false,
    isAuthorView: false,
    isQuestionWrapper: false,
};

export default ChoiceMatrix;
