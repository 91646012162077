import {
    requestAccessTokenWithRefreshToken,
    destroyRefreshToken,
} from '../../helpers/authentication';
import { verifyUserAccess } from '../../helpers/logout/logout';
import ssoLoginUrl from '../../apps/Authentication/urls';
import {
    accessTokenIsValid, setTokenExpiry, getUserToken,
} from '../../helpers/tokenHelper';
import userManager from '../../apps/Authentication/userManager';

const destroySession = async () => {
    try {
        await destroyRefreshToken();
    } catch (error) {
        console.log(error);
    }

    try {
        await verifyUserAccess();
    } catch (error) {
        console.log(error);
    }
};

export default async () => {
    const isAccessTokenValid = accessTokenIsValid();
    const user = await userManager.getUser();
    if (!isAccessTokenValid) {
        try {
            const res = await requestAccessTokenWithRefreshToken();
            const {
                data: { exp },
            } = res;
            setTokenExpiry(exp);
        } catch (error) {
            await destroySession();
            return window.location.assign(`${ssoLoginUrl}?next=${window.location.pathname}`);
        }
    }
    if (user === null && getUserToken() === null) {
        await destroySession();
        return window.location.assign(`${ssoLoginUrl}?next=${window.location.pathname}`);
    }
    return true;
};
