const keys = {
    app: 'cms',
    assessmentLibrary: 'assessmentLibrary',
    assessments: 'assessments',
    district: 'district',
    author: 'author',
    collectionManager: 'collectionManager',
    container: 'container',
    gradeLabel: 'gradeLabel',
    header: 'header',
    name: 'name',
    questionBank: 'questionBank',
    questions: 'questions',
    rubricLibrary: 'rubricLibrary',
    rubrics: 'rubrics',
    actionMenu: 'actionMenu',
    edit: 'edit',
    editQuestion: 'editQuestion',
    newVersion: 'newVersion',
    delete: 'delete',
    viewAssessment: 'viewAssessment',
    viewStandards: 'viewStandards',
    filterDrawer: 'filterDrawer',
    expandAll: 'expandAll',
    collapseAll: 'collapseAll',
    clearAll: 'clearAll',
    collectionDropDownLabel: 'collectionDropDownLabel',
    searchBoxPlaceHolder: 'searchBoxPlaceHolder',
    sharedSearchPlaceHolder: 'sharedSearchPlaceHolder',
    sharedSearchPlaceHolderSchool: 'sharedSearchPlaceHolderSchool',
    allSchoolText: 'allSchoolText',
    titleColumnHeader: 'titleColumnHeader',
    idColumnHeader: 'idColumnHeader',
    statusColumnHeader: 'statusColumnHeader',
    updatedAtColumnHeader: 'updatedAtColumnHeader',
    actionsColumnHeader: 'actionsColumnHeader',
    questionPartsLabel: 'questionPartsLabel',
    typeLabel: 'typeLabel',
    commaWithSpace: 'commaWithSpace',
    versionLabel: 'versionLabel',
    selectButtonText: 'selectButtonText',
    applyButtonText: 'applyButtonText',
    applyButton: 'applyButton',
    okButton: 'okButton',
    cancelButton: 'cancelButton',
    standardsButtonText: 'standardsButtonText',
    showMoreButtonText: 'showMoreButtonText',
    showLessButtonText: 'showLessButtonText',
    deleteMessage: 'deleteMessage',
    assessmentTitle: 'assessmentTitle',
    standardsTitle: 'standardsTitle',
    yes: 'yes',
    no: 'no',
    close: 'close',
    moreAction: 'moreAction',
    standards: 'standards',
    standardSetLabel: 'standardSetLabel',
    showStandardsLabel: 'showStandardsLabel',
    selectStandards: 'selectStandards',
    selectedStandards: 'selectedStandards',
    standardsSelected: 'standardsSelected',
    defaultText: 'defaultText',
    saveButtonText: 'saveButtonText',
    cancelButtonText: 'cancelButtonText',
    title: 'title',
    createPopUpHeadingText: 'createPopUpHeadingText',
    editPopUpHeadingText: 'editPopUpHeadingText',
    collectionButtonText: 'collectionButtonText',
    selectStandard: 'selectStandard',
    grades: 'grades',
    questionTypes: 'questionTypes',
    status: 'status',
    filterOneItemsLabel: 'filterOneItemsLabel',
    filterTwoItemsLabel: 'filterTwoItemsLabel',
    filterThreeItemsLabel: 'filterThreeItemsLabel',
    common: 'common',
    usedInColumnHeader: 'usedInColumnHeader',
    createRubric: 'createRubric',
    statusDropDownLabel: 'statusDropDownLabel',
    defaultAuthor: 'defaultAuthor',
    id: 'id',
    sharedLabel: 'sharedLabel',
    subjectDropDownLabel: 'subjectDropDownLabel',
    titlePlaceholder: 'titlePlaceholder',
    authorPlaceholder: 'authorPlaceholder',
    teacherGeneralInstructionsLabel: 'teacherGeneralInstructionsLabel',
    studentGeneralInstructionsLabel: 'studentGeneralInstructionsLabel',
    teacherDescriptionLabel: 'teacherDescriptionLabel',
    studentDescriptionLabel: 'studentDescriptionLabel',
    save: 'save',
    preview: 'preview',
    rubricPropertiesStepperLabel: 'rubricPropertiesStepperLabel',
    rubricOptionsStepperLabel: 'rubricOptionsStepperLabel',
    labelPlaceholder: 'labelPlaceholder',
    pointsPlaceholder: 'pointsPlaceholder',
    addRubricOption: 'addRubricOption',
    points: 'points',
    moveAbove: 'moveAbove',
    moveBelow: 'moveBelow',
    next: 'next',
    previous: 'previous',
    asTeacher: 'asTeacher',
    asStudent: 'asStudent',
    pointWithEnd: 'pointWithEnd',
    pointStart: 'pointStart',
    questionPartsCount: 'questionPartsCount',
    pointsCount: 'pointsCount',
    addQuestionPartButton: 'addQuestionPartButton',
    previousButton: 'previousButton',
    addQuestionPartMessage: 'addQuestionPartMessage',
    questionTypePointsAccordion: 'questionTypePointsAccordion',
    setPropertiesAccordion: 'setPropertiesAccordion',
    selectStandardsAccordion: 'selectStandardsAccordion',
    studentInstructionsLabel: 'studentInstructionsLabel',
    hintDescriptionLabel: 'hintDescriptionLabel',
    depthOfKnowledgeLabel: 'depthOfKnowledgeLabel',
    tagsLabel: 'tagsLabel',
    questionItem: 'questionItem',
    noRubricOption: 'noRubricOption',
    rubricTitleRequiredError: 'rubricTitleRequiredError',
    authorRequiredError: 'authorRequiredError',
    rubricPartLabelRequiredError: 'rubricPartLabelRequiredError',
    rubricPartPointsRequiredError: 'rubricPartPointsRequiredError',
    moreText: 'moreText',
    rubicNameText: 'rubicNameText',
    rubricLabelText: 'rubricLabelText',
    applyCapsButtonText: 'applyCapsButtonText',
    cancelCapsButtonText: 'cancelCapsButtonText',
    rubricHeadingText: 'rubricHeadingText',
    newRubric: 'newRubric',
    createQuestion: 'createQuestion',
    saveAsNewVersion: 'saveAsNewVersion',
    noAssessment: 'noAssessment',
    noStandard: 'noStandard',
    warning: 'warning',
    generalConfirmation: 'generalConfirmation',
    publishedToDraftWithoutQuestionWarninig: 'publishedToDraftWithoutQuestionWarninig',
    publishedToDraftWithQuestionWarninig: 'publishedToDraftWithQuestionWarninig',
    publishedWithQuestionWarninig: 'publishedWithQuestionWarninig',
    draftToPublishWithNoParts: 'draftToPublishWithNoParts',
    publishWithNoParts: 'publishWithNoParts',
    yesButtonText: 'yesButtonText',
    noButtonText: 'noButtonText',
    confirmationMsg: 'confirmationMsg',
    proceedMsg: 'proceedMsg',
    selectedText: 'selectedText',
    appliedText: 'appliedText',
    shortDescriptionLabelText: 'shortDescriptionLabelText',
    statusDraftlabel: 'statusDraftlabel',
    statusPublishlabel: 'statusPublishlabel',
    statusDraft: 'statusDraft',
    statusPublish: 'statusPublish',
    stepQuestionProperties: 'stepQuestionProperties',
    stepQuestionParts: 'stepQuestionParts',
    dataResetWarning: 'dataResetWarning',
    collectionErrorMessage: 'collectionErrorMessage',
    rubricTitleLabel: 'rubricTitleLabel',
    emptyRubricsTableText: 'emptyRubricsTableText',
    emptyQuestionsTableText: 'emptyQuestionsTableText',
    createAssessment: 'createAssessment',
    version: 'version',
    type: 'type',
    lastUpdated: 'lastUpdated',
    grade: 'grade',
    assessmentTitleLabel: 'assessmentTitleLabel',
    tags: 'tags',
    tag: 'tag',
    assessmentPropertiesStepperLabel: 'assessmentPropertiesStepperLabel',
    assessmentQuestionsStepperLabel: 'assessmentQuestionsStepperLabel',
    assessmentRenderingStepperLabel: 'assessmentRenderingStepperLabel',
    scoringGeneralInstructionsLabel: 'scoringGeneralInstructionsLabel',
    assessmentTitleRequiredError: 'assessmentTitleRequiredError',
    briefDesc: 'briefDesc',
    briefDescPlaceholder: 'briefDescPlaceholder',
    altImgTextLabel: 'altImgTextLabel',
    assessmentTypeDropDownLabel: 'assessmentTypeDropDownLabel',
    assessmentTypeDropDownPlaceHolder: 'assessmentTypeDropDownPlaceHolder',
    gradePlaceHolder: 'gradePlaceHolder',
    addQuestions: 'addQuestions',
    questionsCount: 'questionsCount',
    noQuestions: 'noQuestions',
    assessmentTypeRequiredError: 'assessmentTypeRequiredError',
    gradeRequiredError: 'gradeRequiredError',
    maxImageSize: 'maxImageSize',
    updateImageButton: 'updateImageButton',
    tagsPlaceholderText: 'tagsPlaceholderText',
    noResultsFoundText: 'noResultsFoundText',
    noDataAvailable: 'noDataAvailable',
    pageTitleText: 'pageTitleText',
    actions: 'actions',
    emptyAssessmentTableText: 'emptyAssessmentTableText',
    viewAsStudent: 'viewAsStudent',
    viewAsTeacher: 'viewAsTeacher',
    deleteConfirmationMessage: 'deleteConfirmationMessage',
    deleteMessageNote: 'deleteMessageNote',
    deleteConfirmationQuestion: 'deleteConfirmationQuestion',
    titleRequiredError: 'titleRequiredError',
    questionAuthorRequiredError: 'questionAuthorRequiredError',
    enterTitlePlaceholder: 'enterTitlePlaceholder',
    enterAuthorPlaceholder: 'enterAuthorPlaceholder',
    enterCopyrightInfoPlaceholder: 'enterCopyrightInfoPlaceholder',
    copyrightInfoText: 'copyrightInfoText',
    of: 'of',
    questionPrefixLabelPlaceholder: 'questionPrefixLabelPlaceholder',
    prefixLabelPlaceholder: 'prefixLabelPlaceholder',
    rubricUsed: 'rubricUsed',
    tagsPlaceHolder: 'tagsPlaceHolder',
    assessmentRendering: 'assessmentRendering',
    assessmentSetting: 'assessmentSetting',
    assessmentTypes: 'assessmentTypes',
    standardDomain: 'standardDomain',
    assessmentQuestionTypes: 'assessmentQuestionTypes',
    newAssessment: 'newAssessment',
    assessmentLabel: 'assessmentLabel',
    updatedOnLabel: 'updatedOnLabel',
    showMoreLabel: 'showMoreLabel',
    showLessLabel: 'showLessLabel',
    questionByQuestionLabel: 'questionByQuestionLabel',
    mandatoryQuestionsLabel: 'mandatoryQuestionsLabel',
    scoringGuidanceLabel: 'scoringGuidanceLabel',
    viewLabel: 'viewLabel',
    publishedToDraftWithoutQuestionWarning: 'publishedToDraftWithoutQuestionWarning',
    publishedToDraftWithQuestionWarning: 'publishedToDraftWithQuestionWarning',
    publishedWithQuestionWarning: 'publishedWithQuestionWarning',
    draftToPublishWithNoQuestion: 'draftToPublishWithNoQuestion',
    publishWithNoQuestion: 'publishWithNoQuestion',
    newVersionAssessmentUpdate: 'newVersionAssessmentUpdate',
    newVersionUpdate: 'newVersionUpdate',
    updateError: 'updateError',
    addQuestionsText: 'addQuestionsText',
    assessmentAlreadyassigned: 'assessmentAlreadyassigned',
    standardsLabel: 'standardsLabel',
    maxImageSizeErrorText: 'maxImageSizeErrorText',
    resetText: 'resetText',
    validateAndPreviewLabel: 'validateAndPreviewLabel',
    xmlPlaceholder: 'xmlPlaceholder',
    xmlSyntaxError: 'xmlSyntaxError',
    scoringTypeLabel: 'scoringTypeLabel',
    pointsLabel: 'pointsLabel',
    enterPointsLabel: 'enterPointsLabel',
    rubricsLabel: 'rubricsLabel',
    maxPointsLabel: 'maxPointsLabel',
    noRubricsText: 'noRubricsText',
    alternativeImagePlaceholder: 'alternativeImagePlaceholder',
    screenByScreenText: 'screenByScreenText',
    questionOnSameScreenText: 'questionOnSameScreenText',
    questionMandatoryText: 'questionMandatoryText',
    questionSkippableText: 'questionSkippableText',
    createNewVersionOfAssessment: 'createNewVersionOfAssessment',
    questionGroups: 'questionGroups',
    createGroup: 'createGroup',
    groups: 'groups',
    addQuestion: 'addQuestion',
    addGroup: 'addGroup',
    add: 'add',
    viewAssessmentForGroup: 'viewAssessmentForGroup',
    deleteGroupMessage: 'deleteGroupMessage',
    emptyQuestionGroupsTableText: 'emptyQuestionGroupsTableText',
    newQuestionGroup: 'newQuestionGroup',
    questionGroupTitleLabel: 'questionGroupTitleLabel',
    alreadyassigned: 'alreadyassigned',
    newVersionQuestionGroupUpdate: 'newVersionQuestionGroupUpdate',
    groupPrefixLabelPlaceholder: 'groupPrefixLabelPlaceholder',
    addQuestionsGroupText: 'addQuestionsGroupText',
    all: 'all',
    questionLabel: 'questionLabel',
    assignmentLabel: 'assignmentLabel',
    assign: 'assign',
    share: 'share',
    teacher: 'teacher',
    downloadPdf: 'downloadPdf',
    correctAnswer: 'correctAnswer',
    gradingTypes: 'gradingTypes',
    download: 'download',
    previewLabel: 'previewLabel',
    assignmentTitle: 'assignmentTitle',
    updated: 'updated',
    assignPage: 'assignPage',
    newAssignment: 'newAssignment',
    noPreviewText: 'noPreviewText',
    clickValidateAndPreview: 'clickValidateAndPreview',
    existingAssignment: 'existingAssignment',
    settings: 'settings',
    selectClassAndStudent: 'selectClassAndStudent',
    openDate: 'openDate',
    closeDate: 'closeDate',
    startDateInfo: 'startDateInfo',
    dueDateInfo: 'dueDateInfo',
    selectDataAndTime: 'selectDataAndTime',
    selectDate: 'selectDate',
    openManually: 'openManually',
    closeManually: 'closeManually',
    openNow: 'openNow',
    closeNow: 'closeNow',
    doneOnPaper: 'doneOnPaper',
    shareRubric: 'shareRubric',
    assignDate: 'assignDate',
    className: 'className',
    teacherName: 'teacherName',
    studentNo: 'studentNo',
    viewStatus: 'viewStatus',
    studentsSelected: 'studentsSelected',
    usersSelected: 'usersSelected',
    openCallout: 'openCallout',
    closeCallout: 'closeCallout',
    total: 'total',
    assignmentsCenter: 'assignmentsCenter',
    student: 'student',
    enterPointsAriaLabel: 'enterPointsAriaLabel',
    twigScienceAssessments: 'twigScienceAssessments',
    dashboard: 'dashboard',
    open: 'open',
    completed: 'completed',
    start: 'start',
    dueDate: 'dueDate',
    class: 'class',
    classLabel: 'classLabel',
    dueToday: 'dueToday',
    dueTommorrow: 'dueTommorrow',
    new: 'new',
    classText: 'classText',
    filter: 'filter',
    dueDateAndTimeText: 'dueDateAndTimeText',
    typeAndTitleText: 'typeAndTitleText',
    sortByText: 'sortByText',
    result: 'result',
    pointsAchievedText: 'pointsAchievedText',
    closed: 'closed',
    continue: 'continue',
    paused: 'paused',
    awaitingResults: 'awaitingResults',
    viewResults: 'viewResults',
    quickAccess: 'quickAccess',
    noAssignmentsDue: 'noAssignmentsDue',
    previewTitleLabel: 'previewTitleLabel',
    previewNoQuestions: 'previewNoQuestions',
    noMoreRecent: 'noMoreRecent',
    assessmentType: 'assessmentType',
    classPrefix: 'classPrefix',
    classSufix: 'classSufix',
    noAssignmentsText: 'noAssignmentsText',
    player: 'player',
    instructionsOnly: 'instructionsOnly',
    instructionsAndQuestions: 'instructionsAndQuestions',
    questionsOnly: 'questionsOnly',
    back: 'back',
    submit: 'submit',
    notAccessedAssignments: 'notAccessedAssignments',
    noCompletedAssignments: 'noCompletedAssignments',
    viewUpcomingAssessments: 'viewUpcomingAssessments',
    viewAll: 'viewAll',
    groupPreviewTitleLabel: 'groupPreviewTitleLabel',
    groupPreviewQuestionLabel: 'groupPreviewQuestionLabel',
    groupPreviewNoQuestions: 'groupPreviewNoQuestions',
    defaultAltImgText: 'defaultAltImgText',
    cancelBtnText: 'cancelBtnText',
    addBtnText: 'addBtnText',
    warningmsg: 'warningmsg',
    addButtonText: 'addButtonText',
    groupsSelectedText: 'groupsSelectedText',
    questionsSelected: 'questionsSelected',
    xml: 'xml',
    questionPart: 'questionPart',
    print: 'print',
    uploadPdf: 'uploadPdf',
    uploadPdfLabel: 'uploadPdfLabel',
    maxFileSize: 'maxFileSize',
    maxFileSizeErrorText: 'maxFileSizeErrorText',
    invalidPdfErrorText: 'invalidPdfErrorText',
    dueTodayTag: 'dueTodayTag',
    publishedWithQuestionAndAssessmentWarning: 'publishedWithQuestionAndAssessmentWarning',
    stage: 'stage',
    startDate: 'startDate',
    createdOn: 'createdOn',
    typeColumnHeader: 'typeColumnHeader',
    assessmentColumnHeader: 'assessmentColumnHeader',
    emptyAssignmentsTableText: 'emptyAssignmentsTableText',
    pause: 'pause',
    resume: 'resume',
    startNow: 'startNow',
    endNow: 'endNow',
    viewStudentAnswers: 'viewStudentAnswers',
    googleClassroom: 'googleClassroom',
    questionBankItems: 'questionBankItems',
    items: 'items',
    question: 'question',
    noItems: 'noItems',
    questionCount: 'questionCount',
    allTypes: 'allTypes',
    closedOnText: 'closedOnText',
    unableToSubmit: 'unableToSubmit',
    returnToAssessment: 'returnToAssessment',
    unableToSubmitMsg: 'unableToSubmitMsg',
    confirmSubmissionMsg: 'confirmSubmissionMsg',
    submitBtnText: 'submitBtnText',
    emergingText: 'emergingText',
    developingText: 'developingText',
    proficientText: 'proficientText',
    advancedText: 'advancedText',
    linkCopiedText: 'linkCopiedText',
    viewRubric: 'viewRubric',
    teacherLinkText: 'teacherLinkText',
    copyLinkText: 'copyLinkText',
    attemptsSavedMsg: 'attemptsSavedMsg',
    teacherAssessmentPreviewText: 'teacherAssessmentPreviewText',
    plus: 'plus',
    moreLabel: 'moreLabel',
    dismiss: 'dismiss',
    okBtnText: 'okBtnText',
    closeBtnText: 'closeBtnText',
    gradingView: 'gradingView',
    assignmentDetails: 'assignmentDetails',
    assessmentTags: 'assessmentTags',
    assignedOn: 'assignedOn',
    assignedBy: 'assignedBy',
    relatedAssignments: 'relatedAssignments',
    studentRubrics: 'studentRubrics',
    renderingMode: 'renderingMode',
    skipOptions: 'skipOptions',
    assessmentGrade: 'assessmentGrade',
    editAssessment: 'editAssessment',
    removeStudent: 'removeStudent',
    markAsStudent: 'markAsStudent',
    forceSubmit: 'forceSubmit',
    shareResults: 'shareResults',
    exportCSV: 'exportCSV',
    addStudent: 'addStudent',
    onLabel: 'onLabel',
    offLabel: 'offLabel',
    shared: 'shared',
    notShared: 'notShared',
    assignment: 'assignment',
    endassignment: 'endassignment',
    archive: 'archive',
    returnToDashboard: 'returnToDashboard',
    assignmentSubmitMessage: 'assignmentSubmitMessage',
    assignmentSubmissionInstruction: 'assignmentSubmissionInstruction',
    windowUnloadMsg: 'windowUnloadMsg',
    submitted: 'submitted',
    graded: 'graded',
    statusColumnHeaderText: 'statusColumnHeaderText',
    createdBy: 'createdBy',
    standardsApplied: 'standardsApplied',
    noStageAssignment: 'noStageAssignment',
    savedMessage: 'savedMessage',
    dismissText: 'dismissText',
    scoringView: 'scoringView',
    overallScore: 'overallScore',
    correctAnswerView: 'correctAnswerView',
    pointsLabelText: 'pointsLabelText',
    pointLabelText: 'pointLabelText',
    questionLabelText: 'questionLabelText',
    pointsAvailable: 'pointsAvailable',
    correctAnswerLabel: 'correctAnswerLabel',
    submissionDate: 'submissionDate',
    userAnswerLabel: 'userAnswerLabel',
    assignTo: 'assignTo',
    students: 'students',
    removeAssignmentDescription: 'removeAssignmentDescription',
    removeAssignmentDescriptionCount: 'removeAssignmentDescriptionCount',
    removeWarning: 'removeWarning',
    removeButton: 'removeButton',
    noStudentsForAssignments: 'noStudentsForAssignments',
    shareResultsDescription: 'shareResultsDescription',
    shareResultsDescriptionCount: 'shareResultsDescriptionCount',
    shareResultsWarning: 'shareResultsWarning',
    markAsAbsent: 'markAsAbsent',
    markAsAbsentAssignmentDescription: 'markAsAbsentAssignmentDescription',
    markAsAbsentAssignmentDescriptionCount: 'markAsAbsentAssignmentDescriptionCount',
    markAsAbsentWarning: 'markAsAbsentWarning',
    forceSubmitTitle: 'forceSubmitTitle',
    forceSubmitDescriptionCount: 'forceSubmitDescriptionCount',
    forceSubmitDescription: 'forceSubmitDescription',
    forceSubmitWarning: 'forceSubmitWarning',
    addStudentHeader: 'addStudentHeader',
    removeSnackText: 'removeSnackText',
    forceSnackText: 'forceSnackText',
    absentSnackText: 'absentSnackText',
    shareSnackText: 'shareSnackText',
    submittedOnText: 'submittedOnText',
    viewAnswers: 'viewAnswers',
    studentName: 'studentName',
    submitedDateAndTimeSpent: 'submitedDateAndTimeSpent',
    dueDateText: 'dueDateText',
    disabledButtonTooltip: 'disabledButtonTooltip',
    notGraded: 'notGraded',
    partiallyGraded: 'partiallyGraded',
    gardedQuestionitems: 'gardedQuestionitems',
    noClassAssign: 'noClassAssign',
    exportStart: 'exportStart',
    exportEnd: 'exportEnd',
    absent: 'absent',
    orText: 'orText',
    disabledGroupPreviewTooltip: 'disabledGroupPreviewTooltip',
    disabledGroupSaveTooltip: 'disabledGroupSaveTooltip',
    previewBtnTooltip: 'previewBtnTooltip',
    saveBtnTooltip: 'saveBtnTooltip',
    noStandardsAvailable: 'noStandardsAvailable',
    copyTeacherLinkText: 'copyTeacherLinkText',
    resultUpdatedText: 'resultUpdatedText',
    pointsErrorText: 'pointsErrorText',
    warningText: 'warningText',
    graphQLErrorMessage: 'graphQLErrorMessage',
    retryText: 'retryText',
    cancelText: 'cancelText',
    archiveAssignment: 'archiveAssignment',
    archiveAssignmentDescription: 'archiveAssignmentDescription',
    archiveAssignmentDueDateDescription: 'archiveAssignmentDueDateDescription',
    archiveAssignmentWarning: 'archiveAssignmentWarning',
    progress: 'progress',
    genericError: 'genericError',
    errorBoundaryMessage: 'errorBoundaryMessage',
    logOutText: 'logOutText',
    myProfileText: 'myProfileText',
    item: 'item',
    login: 'login',
    logInText: 'logInText',
    signInText: 'signInText',
    logInWithText: 'logInWithText',
    twigScience: 'twigScience',
    invalidLoginText: 'invalidLoginText',
    selectScoreLevel: 'selectScoreLevel',
    pausedText: 'pausedText',
    point: 'point',
    singleQuestion: 'singleQuestion',
    updateText: 'updateText',
    mandatoryText: 'mandatoryText',
    viewMoreTags: 'viewMoreTags',
    pluralPoint: 'pluralPoint',
    pluralItem: 'pluralItem',
    pluralQuestion: 'pluralQuestion',
    errorMessageForSelectStudents: 'errorMessageForSelectStudents',
    scoringInstructionsText: 'scoringInstructionsText',
    callout: 'callout',
    singleCriteria: 'singleCriteria',
    multipleCriteria: 'MultipleCriteria',
    noRubricCriteria: 'noRubricCriteria',
    enterCriteriaName: 'enterCriteriaName',
    rubricDataLost: 'rubricDataLost',
    rubricDataLostProceedMsg: 'rubricDataLostProceedMsg',
    selectStudentText: 'selectStudentText',
    addCriteriaButtonText: 'addCriteriaButtonText',
    outOfBoxError: 'outOfBoxError',
    warningInstruction: 'warningInstruction',
    warningReminder: 'warningReminder',
    saveChanges: 'saveChanges',
    warningDescriptionText: 'warningDescriptionText',
    locked: 'locked',
    criteriaNameRequiredError: 'criteriaNameRequiredError',
    copyStudentLink: 'copyStudentLink',
    studentLinkCopied: 'studentLinkCopied',
    filterText: 'filterText',
    scoring: 'scoring',
    overviewText: 'overviewText',
    startWarningMsg: 'startWarningMsg',
    pauseWarningMsg: 'pauseWarningMsg',
    resumeWarningMsg: 'resumeWarningMsg',
    endWarningMsg: 'endWarningMsg',
    byText: 'byText',
    offlineAnswer: 'offlineAnswer',
    globalCheckText: 'globalCheckText',
    infoCallout: 'infoCallout',
    completedAssessmentOffline: 'completedAssessmentOffline',
    classColumnHeaderText: 'classColumnHeaderText',
    questionItemText: 'questionItemText',
    itemText: 'itemText',
    questionAnsweredOnPaper: 'questionAnsweredOnPaper',
    yesBtnText: 'yesBtnText',
    noBtnText: 'noBtnText',
    createNewVersionBtnText: 'createNewVersionBtnText',
    goBackBtnText: 'goBackBtnText',
    confirmArchiveBtnText: 'confirmArchiveBtnText',
    confirmShareResultBtnText: 'confirmShareResultBtnText',
    removeSelectedStudent: 'removeSelectedStudent',
    confirmRemoveStudentBtnText: 'confirmRemoveStudentBtnText',
    forceSubmitText: 'forceSubmitText',
    confirmForceSubmitBtnText: 'confirmForceSubmitBtnText',
    confirmYesSaveBtnText: 'confirmYesSaveBtnText',
    confirmNoBtnText: 'confirmNoBtnText',
    gradingViewPopupMsg: 'gradingViewPopupMsg',
    confirmationPromptMsg: 'confirmationPromptMsg',
    leaveText: 'leaveText',
    toBeGradedDropdown: 'toBeGradedDropdown',
    gradedDropdown: 'gradedDropdown',
    inProgressDropdown: 'inProgressDropdown',
    absentDropdown: 'absentDropdown',
    shareResultPopup: 'shareResultPopup',
    yourAnswerText: 'yourAnswerText',
    manuallyGradedRubric: 'manuallyGradedRubric',
    manuallyGraded: 'manuallyGraded',
    previewCallOut: 'previewCallOut',
    jumpToText: 'jumpToText',
    backToText: 'backToText',
    selectFromDropDownText: 'selectFromDropDownText',
    autoScoredText: 'autoScoredText',
    scoreText: 'scoreText',
    showRubricText: 'showRubricText',
    scoreUsingRubricText: 'scoreUsingRubricText',
    pointsAvailabelText: 'pointsAvailabelText',
    resetAutoScoreText: 'resetAutoScoreText',
    printRubricButton: 'printRubricButton',
    showAllRubrics: 'showAllRubrics',
    hideAllRubrics: 'hideAllRubrics',
    showRubric: 'showRubric',
    hideRubric: 'hideRubric',
    scoreUsingRubric: 'scoreUsingRubric',
    rubricInSplitView: 'rubricInSplitView',
    deleteQuestionTooltip: 'deleteQuestionTooltip',
    previewQuestionTooltip: 'previewQuestionTooltip',
    dragIconTooltip: 'dragIconTooltip',
    moveActionTooltip: 'moveActionTooltip',
    expandIconTooltip: 'expandIconTooltip',
    collapseIconTooltip: 'collapseIconTooltip',
    listViewTooltip: 'listViewTooltip',
    cardViewTooltip: 'cardViewTooltip',
    copyCollection: 'copyCollection',
    copyRubrics: 'copyRubrics',
    copyQuestions: 'copyQuestions',
    copyGroups: 'copyGroups',
    copyAssessments: 'copyAssessments',
    copyAssessmentsWithFiles: 'copyAssessmentsWithFiles',
    sourceCollection: 'sourceCollection',
    targetCollection: 'targetCollection',
    selectCollection: 'selectCollection',
    standardManager: 'standardManager',
    uploadStandard: 'uploadStandard',
    updateStandard: 'updateStandard',
    uploadRelatedStandard: 'uploadRelatedStandard',
    admin: 'admin',
    userCreation: 'userCreation',
    uploadDistrict: 'uploadDistrict',
    uploadOrganization: 'uploadOrganization',
    uploadClass: 'uploadClass',
    uploadUser: 'uploadUser',
    uploadUserOrganizationMapping: 'uploadUserOrganizationMapping',
    uploadEnrollment: 'uploadEnrollment',
    invalidCsvErrorText: 'invalidCsvErrorText',
    deleteCollection: 'deleteCollection',
    deleteCollectionData: 'deleteCollectionData',
    replaceStandards: 'replaceStandards',
    markAsAbsentWarningTitle: 'markAsAbsentWarningTitle',
    errorWhileSaving: 'errorWhileSaving',
    shortName: 'shortName',
    invalidShortNameError: 'invalidShortNameError',
    shortNameAlreadyExistsError: 'shortNameAlreadyExistsError',
    listView: 'listView',
    cardView: 'cardView',
    togglePressed: 'togglePressed',
    toggleNotPressed: 'toggleNotPressed',
    nextQuestion: 'nextQuestion',
    previousQuestion: 'previousQuestion',
    nextStudent: 'nextStudent',
    previousStudent: 'previousStudent',
    accessibility: 'accessibility',
    viewAllAssessments: 'viewAllAssessments',
    dueForToday: 'dueForToday',
    dueOnText: 'dueOnText',
    assignedOnText: 'assignedOnText',
    gradeAssignmentText: 'gradeAssignmentText',
    reportsText: 'reportsText',
    viewAssignmentText: 'viewAssignmentText',
    moreTags: 'moreTags',
    associatedAssessmentsTooltip: 'associatedAssessmentsTooltip',
    associatedAssessmentsLabel: 'associatedAssessmentsLabel',
    associatedAssessmentsPlaceholder: 'associatedAssessmentsPlaceholder',
    associatedAssessments: 'associatedAssessments',
    languageLabel: 'languageLabel',
    languageRequired: 'languageRequired',
    invalidAssociationId: 'invalidAssociationId',
    invalidCountAssessment: 'invalidCountAssessment',
    invalidIdFormat: 'invalidIdFormat',
    itemType: 'itemType',
    clickableExistingAssessments: 'clickableExistingAssessments',
    selectLangaugePlaceholder: 'selectLangaugePlaceholder',
    selfChipIdError: 'selfChipIdError',
    selectGradePlaceholder: 'selectGradePlaceholder',
    fromText: 'fromText',
    toText: 'toText',
    viewInstructions: 'viewInstructions',
    reports: 'reports',
    gradesID: 'gradesID',
    appliedFiltersText: 'appliedFiltersText',
    changeStudent: 'changeStudent',
    byAssessment: 'byAssessment',
    byStudent: 'byStudent',
    growthMonitoring: 'growthMonitoring',
    percentage: 'percentage',
    viewMode: 'viewMode',
    uniqueLogins: 'uniqueLogins',
    viewBy: 'viewBy',
    emptyTableText: 'emptyTableText',
    enterIdsText: 'enterIdsText',
    assessmentIdsText: 'assessmentIdsText',
    assessmentId: 'assessmentId',
    assessmentIdError: 'assessmentIdError',
    assessmentLanguageLabel: 'assessmentLanguageLabel',
    totalAssessmentsLabel: 'totalAssessmentsLabel',
    gradeBreakdownLabel: 'gradeBreakdownLabel',
    searchLabelAssessmentId: 'searchLabelAssessmentId',
    assessmentsOverviewLabel: 'assessmentsOverviewLabel',
    detailsLabel: 'detailsLabel',
    lastAssignedText: 'lastAssignedText',
    avgScoreText: 'avgScoreText',
    percentSign: 'percentSign',
    assignmentSign: 'assignmentSign',
    assessmentReportLabel: 'assessmentReportLabel',
    itemAnalysisLabel: 'itemAnalysisLabel',
    summaryLabel: 'summaryLabel',
    assessmentTypesTitle: 'assessmentTypesTitle',
    pieChartInfo: 'pieChartInfo',
    gradeTKLabel: 'gradeTKLabel',
    gradeKGLabel: 'gradeKGLabel',
    gradeOneLabel: 'gradeOneLabel',
    gradeTwoLabel: 'gradeTwoLabel',
    gradeThreeLabel: 'gradeThreeLabel',
    gradeFourLabel: 'gradeFourLabel',
    gradeFiveLabel: 'gradeFiveLabel',
    gradeSixLabel: 'gradeSixLabel',
    emptyTextForClassesReport: 'emptyTextForClassesReport',
    emptyTextForStudentReport: 'emptyTextForStudentReport',
    assignmentReportLabel: 'assignmentReportLabel',
    viewAssignment: 'viewAssignment',
    gradeAssignment: 'gradeAssignment',
    dueOn: 'dueOn',
    avgScore: 'avgScore',
    maxScore: 'maxScore',
    minScore: 'minScore',
    assignedTo: 'assignedTo',
    assignments: 'assignments',
    sortByLabel: 'sortByLabel',
    userRole: 'userRole',
    typeAndTitle: 'typeAndTitle',
    sortAssessmentsByTypeAndTitle: 'sortAssessmentsByTypeAndTitle',
    sortAssessmentsByAssignedOn: 'sortAssessmentsByAssignedOn',
    searchPlaceHolder: 'searchPlaceHolder',
    searchClassPlaceHolder: 'searchClassPlaceHolder',
    searchPlaceHolderClass: 'searchPlaceHolderClass',
    details: 'details',
    school: 'school',
    searchBySchoolName: 'searchBySchoolName',
    absentTooltip: 'absentTooltip',
    avgScoreTooltip: 'avgScoreTooltip',
    summary: 'summary',
    progressReportForAClass: 'progressReportForAClass',
    assignedOnTooltip: 'assignedOnTooltip',
    jumpToPage: 'jumpToPageLabel',
    jumpToPageError: 'jumpToPageError',
    studentReportsText: 'studentReportsText',
    progressReportsText: 'progressReportsText',
    liveClassMonitoringText: 'liveClassMonitoringText',
    progressReportforStudent: 'progressReportforStudent',
    moreReportsforStudent: 'moreReportsforStudent',
    standardsMastery: 'standardsMastery',
    summaryDashboard: 'summaryDashboard',
    searchByAssessmentName: 'searchByAssessmentName',
    studentScoreText: 'studentScoreText',
    classAvgScoreText: 'classAvgScoreText',
    bandwidthText: 'bandwidthText',
    timeSpentLabel: 'timeSpentLabel',
    classAvgText: 'classAvgText',
    performanceLabel: 'performanceLabel',
    performanceBandwidthTooltip: 'performanceBandwidthTooltip',
    collectionTooltip: 'collectionTooltip',
    assessmentsLabel: 'assessmentsLabel',
    idLabel: 'idLabel',
    assignmentText: 'assignmentText',
    changeAssessment: 'changeAssessment',
    languageText: 'languageText',
    searchByStudentName: 'searchByStudentName',
    emptyStudentReportText: 'emptyStudentReportText',
    collection: 'collection',
    assessmentLanguage: 'assessmentLanguage',
    studentFilterNotAvailable: 'studentFilterNotAvailable',
    studentsAssigned: 'studentsAssigned',
    studentsAbsent: 'studentsAbsent',
    studentsGraded: 'studentsGraded',
    performanceBandwidth: 'performanceBandwidth',
    timeFormat: 'timeFormat',
    itemAnalysisReportLabel: 'itemAnalysisReportLabel',
    answeredOfflineTooltipText: 'answeredOfflineTooltipText',
    lastSixtyDays: 'lastSixtyDays',
    lastThirtyDays: 'lastThirtyDays',
    lastSevenDays: 'lastSevenDays',
    custom: 'custom',
    assessmentGradebookReport: 'assessmentGradebookReport',
    thisAssessmentText: 'thisAssessmentText',
    languageVariantsText: 'languageVariantsText',
    moreReportsText: 'moreReportsText',
    searchByNameOrId: 'searchByNameOrId',
    noReportsAvailable: 'noReportsAvailable',
    answeredOfflineByStudent: 'answeredOfflineByStudent',
    standardsMasteryHeaderText: 'standardsMasteryHeaderText',
    standardsCategoryLabel: 'standardsCategoryLabel',
    standardsMasteryforaStudent: 'standardsMasteryforaStudent',
    standardsMasteryForAClass: 'standardsMasteryForAClass',
    assignmentAvgScore: 'assignmentAvgScore',
    legend: 'legend',
    legendShowText: 'legendShowText',
    legendHideText: 'legendHideText',
    legendShowHideText: 'legendShowHideText',
    searchByStandard: 'searchByStandard',
    searchByStandardOrAssessment: 'searchByStandardOrAssessment',
    progressOverview: 'progressOverview',
    standardCode: 'standardCode',
    changeClassLabel: 'changeClassLabel',
    changeStudentLabel: 'changeStudentLabel',
    searchStandardCode: 'searchStandardCode',
    correctPossibleTooltip: 'correctPossibleTooltip',
    scoreTooltip: 'scoreTooltip',
    standardsGradebookReport: 'standardsGradebookReport',
    sortStandardBy: 'sortStandardBy',
    standardName: 'standardName',
    sortStandardByStandardName: 'sortStandardByStandardName',
    studentsText: 'studentsText',
    standardsMasteryforAssignment: 'standardsMasteryforAssignment',
    standardsReportText: 'standardsReportText',
    pePerformance: 'pePerformance',
    allStandards: 'allStandards',
    studetName: 'studetName',
    studentUsername: 'studentUsername',
    correctPossible: 'correctPossible',
    bandwidthTooltip: 'bandwidthTooltip',
    legendText: 'legendText',
    moreReportsAssignment: 'moreReportsAssignment',
    forText: 'forText',
    assignmentReportForStudentText: 'assignmentReportForStudentText',
    assignmentReportForText: 'assignmentReportForText',
    moreReportsForAssignment: 'moreReportsForAssignment',
    standardReportsForStudent: 'standardReportsForStudent',
    gradebookReport: 'gradebookReport',
    correct: 'correct',
    possible: 'possible',
    standardSet: 'standardSet',
    twoMoreReports: 'twoMoreReports',
    standardCategoryId: 'standardCategoryId',
    domainId: 'domainId',
    domain: 'domain',
    subDomain: 'subDomain',
    emptyTextForReport: 'emptyTextForReport',
    questionItems: 'questionItems',
    itemScore: 'itemScore',
    questionItemScore: 'questionItemScore',
    progressReportForAllClassTitle: 'progressReportForAllClassTitle',
    progressReportForAllClassHeader: 'progressReportForAllClassHeader',
    standardsGradeBookBreadcrumText: 'standardsGradeBookBreadcrumText',
    correctPossiblePointsTooltip: 'correctPossiblePointsTooltip',
    pointsTooltip: 'pointsTooltip',
    selectStandardSet: 'selectStandardSet',
    selectStandardCategory: 'selectStandardCategory',
    studentPerformance: 'studentPerformance',
    noDataAvailableForBandwidth: 'noDataAvailableForBandwidth',
    searchByStandardCode: 'searchByStandardCode',
    dateText: 'dateText',
    standardText: 'standardText',
    selectBandwidth: 'selectBandwidth',
    bandwidthsForText: 'bandwidthsForText',
    usedInText: 'usedInText',
    performanceOverview: 'performanceOverview',
    recentAssessmentPerformance: 'recentAssessmentPerformance',
    assessmentPerformance: 'assessmentPerformance',
    assessmentTypePerformance: 'assessmentTypePerformance',
    standardsPerformance: 'standardsPerformance',
    allClassesText: 'allClassesText',
    allStudentsText: 'allStudentsText',
    avgStudentScore: 'avgStudentScore',
    performanceReport: 'performanceReport',
    noOfAssignments: 'noOfAssignments',
    standardsReportsText: 'standardsReportsText',
    standardCategory: 'standardCategory',
    standardMasteryForText: 'standardMasteryForText',
    inAnAssignmentText: 'inAnAssignmentText',
    forSchoolText: 'forSchoolText',
    forClassText: 'forClassText',
    noFilterInGrowthMonitoring: 'noFilterInGrowthMonitoring',
    moreReportsForThisStudent: 'moreReportsForThisStudent',
    nextRecordsButtonText: 'nextRecordsButtonText',
    nextRecordButtonText: 'nextRecordButtonText',
    prevRecordsButtonText: 'prevRecordsButtonText',
    prevRecordButtonText: 'prevRecordButtonText',
    enterPage: 'enterPage',
    skipToFirstPage: 'skipToFirstPage',
    skipToLastPage: 'skipToLastPage',
    previousPage: 'previousPage',
    nextPage: 'nextPage',
    headerDescription: 'headerDescription',
    selectCollectionText: 'selectCollectionText',
    noCollectionText: 'noCollectionText',
    noCollectionDescription: 'noCollectionDescription',
    selectAssessmentText: 'selectAssessmentText',
    avgScoreForStandards: 'avgScoreForStandards',
    assessmentOverviewCardDescription: 'assessmentOverviewCardDescription',
    assessmentReportCardDescription: 'assessmentReportCardDescription',
    itemAnalysisReportCardDescription: 'itemAnalysisReportCardDescription',
    performanceReportCardDescription: 'performanceReportCardDescription',
    progressReportCardDescription: 'progressReportCardDescription',
    studentPerformanceCardDescription: 'studentPerformanceCardDescription',
    progressReportForAStudentCardDescription: 'progressReportForAStudentCardDescription',
    standardsMasteryForAStudentCardDescription: 'standardsMasteryForAStudentCardDescription',
    standardMasteryForAllClassesCardDescription: 'standardMasteryForAllClassesCardDescription',
    progressOverviewForAStandardCardDescription: 'progressOverviewForAStandardCardDescription',
    gradebookReportCardDescription: 'gradebookReportCardDescription',
    assessmentGradebookReportCardDescription: 'assessmentGradebookReportCardDescription',
    standardsGradebookReportCardDescription: 'standardsGradebookReportCardDescription',
    invalidDateTimeErrorText: 'invalidDateTimeErrorText',
    countOfstudents: 'countOfstudents',
    selectSchoolText: 'selectSchoolText',
    selectSchoolDropdownText: 'selectSchoolDropdownText',
    collectionLabel: 'collectionLabel',
    allDateDropDownLabel: 'allDateDropDownLabel',
    last60DaysDropDownLabel: 'last60DaysDropDownLabel',
    last30DaysDropDownLabel: 'last30DaysDropDownLabel',
    last7DaysDropDownLabel: 'last7DaysDropDownLabel',
    customDateDropDownLabel: 'customDateDropDownLabel',
    avgLabel: 'avgLabel',
    assignedToText: 'assignedToText',
    absentText: 'absentText',
    submittedText: 'submittedText',
    skippedText: 'skippedText',
    answeredText: 'answeredText',
    correctlyText: 'correctlyText',
    inCorrectlyText: 'inCorrectlyText',
    partiallyText: 'partiallyText',
    assignmentScoreLabel: 'assignmentScoreLabel',
    submissionDateLabel: 'submissionDateLabel',
    avgScoreLabel: 'avgScoreLabel',
    viewByText: 'viewByText',
    answersText: 'answersText',
    gradeText: 'gradeText',
    markAsAbsentButton: 'markAsAbsentButton',
    avgScoreForAll: 'avgScoreForAll',
    ngssText: 'ngssText',
    sortClassesBy: 'sortClassesBy',
    classNameText: 'classNameText',
    sortClassesByClassNameText: 'sortClassesByClassNameText',
    selectSubDomain: 'selectSubDomain',
    overallPerformanceText: 'overallPerformanceText',
    adminReportsText: 'adminReportsText',
    schoolPerformanceReportText: 'schoolPerformanceReportText',
    colon: 'colon',
    moreReportsforSchoolText: 'moreReportsforSchoolText',
    assessmentOverviewforSchoolText: 'assessmentOverviewforSchoolText',
    standardsMasteryforSchoolText: 'standardsMasteryforSchoolText',
    classesLabel: 'classeslabel',
    emptySchoolPerformanceReportText: 'emptySchoolPerformanceReportText',
    selectStandardCode: 'selectStandardCode',
    assignmentsAssignedText: 'assignmentsAssignedText',
    noOfStudentsText: 'noOfStudentsText',
    schoolComparisonReportCardDescription: 'schoolComparisonReportCardDescription',
    standardMasteryAllSchoolsCardDescription: 'standardMasteryAllSchoolsCardDescription',
    schoolPerformanceReportCardDescription: 'schoolPerformanceReportCardDescription',
    engangementReportCardDescription: 'engangementReportCardDescription',
    searchBy: 'searchBy',
    progressReportForAllClassText: 'progressReportForAllClassText',
    reopenAssignmentText: 'reopenAssignmentText',
    reopenCheckBoxTooltip: 'reopenCheckBoxTooltip',
    reopenWarningPopupMsg: 'reopenWarningPopupMsg',
    reopenTitleText: 'reopenTitleText',
    reopenText: 'reopenText',
    reopenedText: 'reopenedText',
    reopenedTooltipText: 'reopenedTooltipText',
    reopenWarningLabel: 'reopenWarningLabel',
    reopenWarningMsg: 'reopenWarningMsg',
    shareRubricText: 'shareRubricText',
    updateAnswerFromPreviousText: 'updateAnswerFromPreviousText',
    dueDateErrorMessage: 'dueDateErrorMessage',
    teacherNamelabel: 'teacherNamelabel',
    selectClass: 'selectClass',
    teacherText: 'teacherText',
    schoolsText: 'schoolsText',
    schoolComparisonAssessmentCount: 'schoolComparisonAssessmentCount',
    schoolComparisonAssignmentCount: 'schoolComparisonAssignmentCount',
    schoolComparisonBreadcrumb: 'schoolComparisonBreadcrumb',
    schoolComparisonHeader: 'schoolComparisonHeader',
    schoolsLabel: 'schoolsLabel',
    moreReportSchoolsLabel: 'moreReportSchoolsLabel',
    standardsMasteryAllSchoolsLabel: 'standardsMasteryAllSchoolsLabel',
    schoolsSelectedText: 'schoolsSelectedText',
    noText: 'noText',
    avgScoreSchoolText: 'avgScoreSchoolText',
    avgScoreTooltipForSchool: 'avgScoreTooltipForSchool',
    avgScoreAllText: 'avgScoreAllText',
    collapseText: 'collapseText',
    schoolNameText: 'schoolNameText',
    assessmentNameText: 'assessmentNameText',
    dueOnLabel: 'dueOnLabel',
    avgScoreForAllSchools: 'avgScoreForAllSchools',
    teacherLowerCase: 'teacherLowerCase',
    filterPopup: 'filterPopup',
    characterLimitError: 'characterLimitError',
    invalidLimitError: 'invalidLimitError',
    changeText: 'changeText',
    benchmarkType: 'benchmarkType',
    changeAssessmentType: 'changeAssessmentType',
    teachers: 'teachers',
    maximumFiveCategoriesError: 'maximumFiveCategoriesError',
    summaryNextPage: 'summaryNextPage',
    summaryPreviousPage: 'summaryPreviousPage',
    summaryExpandButton: 'summaryExpandButton',
    summaryCollapseButton: 'summaryCollapseButton',
    invalidXIdentifierInterval: 'invalidXIdentifierInterval',
    invalidRangeYIdentifier: 'invalidRangeYIdentifier',
    majorTickIntervalErrorMessage: 'majorTickIntervalErrorMessage',
    minorTickIntervalErrorMessage: 'minorTickIntervalErrorMessage',
    xAxisMinValueErrorMessage: 'xAxisMinValueErrorMessage',
    xAxisMaxValueErrorMessage: 'xAxisMaxValueErrorMessage',
    yAxisMinValueErrorMessage: 'yAxisMinValueErrorMessage',
    yAxisMaxValueErrorMessage: 'yAxisMaxValueErrorMessage',
    xIdentifierErrorMessage: 'xIdentifierErrorMessage',
    yIdentifierErrorMessage: 'yIdentifierErrorMessage',
    minSnapsRequired: 'minSnapsRequired',
    missingResponseIdentifier: 'missingResponseIdentifier',
    minBarsRequired: 'minBarsRequired',
    missingTextEntryInteractionResponseIdentifier: 'missingTextEntryInteractionResponseIdentifier',
    missingTextEntryInteractionIdentifier: 'missingTextEntryInteractionIdentifier',
    invalidXIdentifierErrorMessage: 'invalidXIdentifierErrorMessage',
    invalidYIdentifierErrorMessage: 'invalidYIdentifierErrorMessage',
    scoringStepperLabel: 'scoringStepperLabel',
    scoringStepperStaticText: 'scoringStepperStaticText',
    scoringStepperNoteBoldText: 'scoringStepperNoteBoldText',
    scoringStepperNoteText: 'scoringStepperNoteText',
    resetButtonText: 'resetButtonText',
    questionGroupNameText: 'questionGroupNameText',
    questionNameText: 'questionNameText',
    multipleItemsText: 'multipleItemsText',
    screenText: 'screenText',
    manualScoredText: 'manualScoredText',
    noContentScreenText: 'noContentScreenText',
    schoolText: 'schoolText',
    clickableAssessment: 'clickableAssessment',
    clickableStudent: 'clickableStudent',
    performanceBandwidthSet: 'performanceBandwidthSet',
    assessmentPerformanceNextPage: 'assessmentPerformanceNextPage',
    assessmentPerformancePreviousPage: 'assessmentPerformancePreviousPage',
    noQuestionErrorText: 'noQuestionErrorText',
    openFilterDrawerText: 'openFilterDrawerText',
    closeFilterDrawerText: 'closeFilterDrawerText',
    menuButtonText: 'menuButtonText',
    invalidColorCodeErrorMessagePoint: 'invalidColorCodeErrorMessagePoint',
    invalidColorCodeErrorMessageBar: 'invalidColorCodeErrorMessageBar',
    pointResponseIdentifierErrorMessage: 'pointResponseIdentifierErrorMessage',
    xAxisMinLessThanMaxErrorMessage: 'xAxisMinLessThanMaxErrorMessage',
    yAxisMinLessThanMaxErrorMessage: 'yAxisMinLessThanMaxErrorMessage',
    xAxisLabelIdentifierMissingError: 'xAxisLabelIdentifierMissingError',
    yAxisLabelIdentifierMissingError: 'yAxisLabelIdentifierMissingError',
    itemBodyMissingError: 'itemBodyMissingError',
    chartDataMissingError: 'chartDataMissingError',
    xAxisMissingError: 'xAxisMissingError',
    yAxisMissingError: 'yAxisMissingError',
    pointMatrixMissingError: 'pointMatrixMissingError',
    xIdentifierBelowMinError: 'xIdentifierBelowMinError',
    xIdentifierAboveMaxError: 'xIdentifierAboveMaxError',
    yIdentifierBelowMinError: 'yIdentifierBelowMinError',
    yIdentifierAboveMaxError: 'yIdentifierAboveMaxError',
    minorTickIntervalGreaterThanMajorTickError: 'minorTickIntervalGreaterThanMajorTickError',
    majorTickIntervalMultipleOfMinorTickError: 'majorTickIntervalMultipleOfMinorTickError',
    gridLabelCountError: 'gridLabelCountError',
    barMatrixMissingError: 'barMatrixMissingError',
    barMissingError: 'barMissingError',
    responseIdentifierForBarMissingError: 'responseIdentifierForBarMissingError',
    responseIdentifierForTextEntryError: 'responseIdentifierForTextEntryError',
    identifierMissingTextEntryInteractionError: 'identifierMissingTextEntryInteractionError',
    barGroupMissingError: 'barGroupMissingError',
    legendMissingError: 'legendMissingError',
    mismatchBarTagCountInBarGroupError: 'mismatchBarTagCountInBarGroupError',
    mismatchBarTagCountWithLegendCountError: 'mismatchBarTagCountWithLegendCountError',
    duplicateXIdentifiersError: 'duplicateXIdentifiersError',
    noOfBarGroupGreaterThanSixError: 'noOfBarGroupGreaterThanSixError',
    missingLegendColorError: 'missingLegendColorError',
    xIdentifierSequentialError: 'xIdentifierSequentialError',
    yGridLabelCountError: 'yGridLabelCountError',
    bundlingDisabled: 'bundlingDisabled',
    resetChangesConfirmation: 'resetChangesConfirmation',
    resetChangesRejection: 'resetChangesRejection',
    resetScoringPopupMessage: 'resetScoringPopupMessage',
    warningBoldText: 'warningBoldText',
    resetQuestionsConfirmation: 'resetQuestionsConfirmation',
    exportRubrics: 'exportRubrics',
    exportRubricItems: 'exportRubricItems',
    exportQuestions: 'exportQuestions',
    exportQuestionItems: 'exportQuestionItems',
    exportQuestionGroups: 'exportQuestionGroups',
    exportAssessments: 'exportAssessments',
    exportAssessmentQuestionParts: 'exportAssessmentQuestionParts',
    nestedTableText: 'nestedTableText',
    buttonText: 'buttonText',
    backToCurrentYearText: 'backToCurrentYearText',
    selectedOrganisationIdKey: 'selectedOrganisationIdKey',
    selectedSchoolYearIdKey: 'selectedSchoolYearIdKey',
    currentYearText: 'currentYearText',
    schoolYearText: 'schoolYearText',
    clickableSchoolYear: 'clickableSchoolYear',
    bundleRubrics: 'bundleRubrics',
    unbundleRubrics: 'unbundleRubrics',
    questionText: 'questionText',
    nextQuestionGradingView: 'nextQuestionGradingView',
    previouQuestionGradingView: 'previouQuestionGradingView',
    viewRubrics: 'viewRubrics',
    zeroPointsMessage: 'zeroPointsMessage',
    zeroPointsArchiveMessage: 'zeroPointsArchiveMessage',
    noteText: 'noteText',
    nonScoredText: 'nonScoredText',
    naText: 'naText',
    revokeAccessTitle: 'revokeAccessTitle',
    revokeAccessDescriptionCount: 'revokeAccessDescriptionCount',
    revokeAccessDescription: 'revokeAccessDescription',
    revokeAccessWarning: 'revokeAccessWarning',
    confirmRevokeAccessText: 'confirmRevokeAccessText',
    revokeSnackText: 'revokeSnackText',
    revokeAndArchiveText: 'revokeAndArchiveText',
    notStartedText: 'notStartedText',
    revokedText: 'revokedText',
    notAssignedText: 'notAssignedText',
    inProgressText: 'inProgressText',
    scoreNALabel: 'scoreNALabel',
    zeroPointMsg: 'zeroPointMsg',
    notApplicableTooltipText: 'notApplicableTooltipText',
    reportsPageRegion: 'reportsPageRegion',
    assessmentDetailRegion: 'assessmentDetailRegion',
    correctAnsViewRegion: 'correctAnsViewRegion',
    assignAssessmentRegion: 'assignAssessmentRegion',
    selectCollectionRegion: 'selectCollectionRegion',
    assessmentListRegion: 'assessmentListRegion',
    assessmentDetailsRegion: 'assessmentDetailsRegion',
    assessmentsOverviewRegion: 'assessmentsOverviewRegion',
    assessmentReportRegion: 'assessmentReportRegion',
    assignmentReportRegion: 'assignmentReportRegion',
    prForAllClassesRegion: 'prForAllClassesRegion',
    prForAClassRegion: 'prForAClassRegion',
    prForAStudentRegion: 'prForAStudentRegion',
    aRForAStudentRegion: 'aRForAStudentRegion',
    itemAnalysisAssignmentRegion: 'itemAnalysisAssignmentRegion',
    itemAnalysisAssessmentRegion: 'itemAnalysisAssessmentRegion',
    gradebookRegion: 'gradebookRegion',
    assessmentGradebookRegion: 'assessmentGradebookRegion',
    standardGradebookRegion: 'standardGradebookRegion',
    smAllClassesRegion: 'smAllClassesRegion',
    smAClassRegion: 'smAClassRegion',
    smStudentRegion: 'smStudentRegion',
    smAssignmentRegion: 'smAssignmentRegion',
    smStudentAssignmentRegion: 'smStudentAssignmentRegion',
    poStandardRegion: 'poStandardRegion',
    performanceRegion: 'performanceRegion',
    schoolComparisonRegion: 'schoolComparisonRegion',
    schoolPerformanceRegion: 'schoolPerformanceRegion',
    smAllSchoolsRegion: 'smAllSchoolsRegion',
    studentDashboardRegion: 'studentDashboardRegion',
    playerHeaderRegion: 'playerHeaderRegion',
    playerContentRegion: 'playerContentRegion',
    standardsContainerRegion: 'standardsContainerRegion',
    questionContentRegion: 'questionContentRegion',
    questionPreviewRegion: 'questionPreviewRegion',
    groupCreationRegion: 'groupCreationRegion',
    rubricListRegion: 'rubricListRegion',
    rubricCreationRegion: 'rubricCreationRegion',
    questionGroupPreviewRegion: 'questionGroupPreviewRegion',
    cmsAssessmentListRegion: 'cmsAssessmentListRegion',
    assessmentCreationRegion: 'assessmentCreationRegion',
    colonSymbol: 'colonSymbol',
    dontShowText: 'dontShowText',
    leftPanelText: 'leftPanelText',
    rightPanelText: 'rightPanelText',
    mainPanelText: 'mainPanelText',
    assessmentInstructionText: 'assessmentInstructionText',
    questionInstructionText: 'questionInstructionText',
    screenByScreenCustomisationText: 'screenByScreenCustomisationText',
    renderQuestionInstruction: 'renderQuestionInstruction',
    instructionPanelLayoutNote: 'instructionPanelLayoutNote',
    introductionScreen: 'introductionScreen',
    questionRenderOptionChangeAlertText: 'questionRenderOptionChangeAlertText',
    questionRenderOptionChangeAcceptText: 'questionRenderOptionChangeAcceptText',
    questionRenderOptionChangeRejectText: 'questionRenderOptionChangeRejectText',
    resetRenderingAlertText: 'resetRenderingAlertText',
    resetRenderingAcceptText: 'resetRenderingAcceptText',
    resetRenderingRejectText: 'resetRenderingRejectText',
    questionGroupInstructionText: 'questionGroupInstructionText',
    toggleHorizontalText: 'toggleHorizontalText',
    toggleVerticalText: 'toggleVerticalText',
    checkboxText: 'checkboxText',
    noStageAssignmentWithAssessmentType: 'noStageAssignmentWithAssessmentType',
    downloadData: 'downloadData',
    downloadCSV: 'downloadCSV',
    clickableText: 'clickableText',
    tagsContainerAriaLabel: 'tagsContainerAriaLabel',
    fileInputAriaLabel: 'fileInputAriaLabel',
    performanceBandwidthButton: 'performanceBandwidthButton',
    pbHeaderTitle: 'pbHeaderTitle',
    emptyCollectionListDraftState: 'emptyCollectionListDraftState',
    emptyCollectionListPublishedState: 'emptyCollectionListPublishedState',
    collectionCreationRegion: 'collectionCreationRegion',
    snackBarCollection: 'snackBarCollection',
    defaultCollectionImageText: 'defaultCollectionImageText',
    collectionNoPublishedWarning: 'collectionNoPublishedWarning',
    warningPopupDataAt: 'warningPopupDataAt',
    shortNamePlaceholder: 'shortNamePlaceholder',
    performanceBandwidthLabel: 'performanceBandwidthLabel',
    removeImage: 'removeImage',
    shortDescriptionPlaceholder: 'shortDescriptionPlaceholder',
    performanceBandwidthTitle: 'performanceBandwidthTitle',
    setPerformanceText: 'setPerformanceText',
    addBandwidthText: 'addBandwidthText',
    duplicateErrorText: 'duplicateErrorText',
    percentSignText: 'percentSignText',
    lessThanArrow: 'lessThanArrow',
    moreThanArrow: 'moreThanArrow',
    moreArrowSign: 'moreArrowSign',
    andText: 'andText',
    performanceBandsText: 'performanceBandsText',
    deleteConfirmationPerformanceBandwidth: 'deleteConfirmationPerformanceBandwidth',
    disabledBandWidthSave: 'disabledBandWidthSave',
    enterCharactersErrorText: 'enterCharactersErrorText',
    sameValueErrorText: 'sameValueErrorText',
    sameLabelValueText: 'sameLabelValueText',
    morePointErrorText: 'morePointErrorText',
    lessPointErrorText: 'lessPointErrorText',
    bandwidthLabelPlaceholderText: 'bandwidthLabelPlaceholderText',
    deleteTooltip: 'deleteTooltip',
    deleteBand: 'deleteBand',
    newBandwidthError: 'newBandwidthError',
    newTag: 'newTag',
    subscriptionManager: 'subscriptionManager',
    districtDropDownLabel: 'districtDropDownLabel',
    addSubscription: 'addSubscription',
    removeSubscription: 'removeSubscription',
    selectDistrict: 'selectDistrict',
    optionCollectionAriaLabel: 'optionCollectionAriaLabel',
    newTagIconAriaLabel: 'newTagIconAriaLabel',
    collectionsDropDownAriaLabel: 'collectionsDropDownAriaLabel',
    publishedToDraftWarningText: 'publishedToDraftWarningText',
    publishedToDraftWarningNoteText: 'publishedToDraftWarningNoteText',
    moveQuestionInstructionLeft: 'moveQuestionInstructionLeft',
    moveQuestionInstructionMain: 'moveQuestionInstructionMain',
    moveQuestionGrpInstructionLeft: 'moveQuestionGrpInstructionLeft',
    moveQuestionGrpInstructionMain: 'moveQuestionGrpInstructionMain',
    moveAssessmentInstructionDontShow: 'moveAssessmentInstructionDontShow',
    moveAssessmentInstructionLeft: 'moveAssessmentInstructionLeft',
    moveAssessmentInstructionMain: 'moveAssessmentInstructionMain',
    textToSpeechCheckboxText: 'textToSpeechCheckboxText',
    listen: 'listen',
    esListen: 'esListen',
    totalScoreText: 'totalScoreText',
    liveMonitoringDescription: 'liveMonitoringDescription',
    questionItemStatusLabel: 'questionItemStatusLabel',
    incorrectText: 'incorrectText',
    partialyCorrextText: 'partialyCorrextText',
    answerProvidedText: 'answerProvidedText',
    noAnswerText: 'noAnswerText',
    totalPoints: 'totalPoints',
    ScoringLabel: 'ScoringLabel',
    questionItemStatus: 'questionItemStatus',
    liveClassMonitoring: 'liveClassMonitoring',
    classMonitoring: 'classMonitoring',
    answerspreview: 'answerspreview',
    present: 'present',
    liveText: 'liveText',
    studentStatusText: 'studentStatusText',
    studentNameText: 'studentNameText',
    itemTypeText: 'itemTypeText',
    changeAssignment: 'changeAssignment',
    warningLabel: 'warningLabel',
    scoreWithRubricText: 'scoreWithRubricText',
    questionPreviewNoteText: 'questionPreviewNoteText',
    incorrect: 'incorrect',
    partiallyCorrect: 'partiallyCorrect',
    answerProvided: 'answerProvided',
    noAnswer: 'noAnswer',
    notStarted: 'notStarted',
    inProgress: 'inProgress',
    closeButtonAriaLabel: 'closeButtonAriaLabel',
    exitPresentMode: 'exitPresentMode',
    organisationIdOnAssignmentCreation: 'organisationIdOnAssignmentCreation',
    ascendingText: 'ascendingText',
    descendingText: 'descendingText',
    performanceText: 'performanceText',
    screenByScreenValueText: 'screenByScreenValueText',
    allSameScreenValueText: 'allSameScreenValueText',
    deviceCategoryText: 'deviceCategoryText',
    allDevicesText: 'allDevicesText',
    allUsersText: 'allUsersText',
    allSchoolsSelectedText: 'allSchoolsSelectedText',
    noSchoolsSelectedText: 'noSchoolsSelectedText',
    selectSchoolsText: 'selectSchoolsText',
    searchBySchoolsText: 'searchBySchoolsText',
    moreReportForThisDistrictText: 'moreReportForThisDistrictText',
    uniqueLoginsInfoText: 'uniqueLoginsInfoText',
    districts: 'districts',
    districtsSelected: 'districtsSelected',
    imageText: 'imageText',
    imagePreviewText: 'imagePreviewText',
    pointsRubricLabelText: 'pointsRubricLabelText',
    loading: 'loading',
    inGrading: 'inGrading',
    engagementReportText: 'engagementReportText',
    collectionUsageText: 'collectionUsageText',
    assessmentUsageText: 'assessmentUsageText',
    assessmentUsageSchoolText: 'assessmentUsageSchoolText',
    moreReportsDistricts: 'moreReportsDistricts',
    overViewText: 'overViewText',
    usageByBrowser: 'usageByBrowser',
    usageByMonth: 'usageByMonth',
    usageByAssessment: 'usageByAssessment',
    loginSummaryText: 'loginSummaryText',
    loginSummaryTextCloseAriaLabel: 'loginSummaryTextCloseAriaLabel',
    loginSummaryTextOpenAriaLabel: 'loginSummaryTextOpenAriaLabel',
    engagementLineChartOptionDayText: 'engagementLineChartOptionDayText',
    engagementLineChartOptionWeekText: 'engagementLineChartOptionWeekText',
    engagementLineChartOptionMonthText: 'engagementLineChartOptionMonthText',
    engagementLineChartUniqueLoginsText: 'engagementLineChartUniqueLoginsText',
    engagementLineChartTotalLoginsText: 'engagementLineChartTotalLoginsText',
    engagementLineChartType1: 'engagementLineChartType1',
    engagementLineChartType2: 'engagementLineChartType2',
    engagementLineChartXLabel: 'engagementLineChartXLabel',
    searchByDistrictPlaceholder: 'searchByDistrictPlaceholder',
    searchByschoolPlaceholder: 'searchByschoolPlaceholder',
    usersText: 'usersText',
    uniqueText: 'uniqueText',
    loginsText: 'loginsText',
    totalLoginsText: 'totalLoginsText',
    districtText: 'districtText',
    engagementReportDistrictText: 'engagementReportDistrictText',
    moreReportsSchools: 'moreReportsSchools',
    engagementReportSchoolText: 'engagementReportSchoolText',
    searchBySchoolPlaceholder: 'searchBySchoolPlaceholder',
    searchByClassName: 'searchByClassName',
    organizationAndUserSummary: 'organizationAndUserSummary',
    totalOrganizations: 'totalOrganizations',
    totalUsers: 'totalUsers',
    assessmentAssigned: 'assessmentAssigned',
    usageSummary: 'usageSummary',
    totalText: 'totalText',
    engagementAndUsageReportText: 'engagementAndUsageReportText',
    authorText: 'authorText',
    studentText: 'studentText',
    schoolAdminText: 'schoolAdminText',
    districtAdminText: 'districtAdminText',
    twigAdminText: 'twigAdminText',
    allDistrictsSelected: 'allDistrictsSelected',
    noDistrictsSelected: 'noDistrictsSelected',
    engagementLoginHeadCellAriaLabel: 'engagementLoginHeadCellAriaLabel',
    infoIconTextEngagementReport: 'infoIconTextEngagementReport',
    engagementReportForText: 'engagementReportForText',
    totalUsersText: 'totalUsersText',
    totalLogins: 'totalLogins',
    moreReportsForDistricts: 'moreReportsForDistricts',
    itemUsageReportText: 'itemUsageReportText',
    assessmentUsageReportText: 'assessmentUsageReportText',
    collectionSummary: 'collectionSummary',
    assessmentSummary: 'assessmentSummary',
    infoIconSubscribedColections: 'infoIconSubscribedColections',
    infoIconPublishedColections: 'infoIconPublishedColections',
    infoIconUsedColections: 'infoIconUsedColections',
    infoIconPublishedAssessments: 'infoIconPublishedAssessments',
    infoIconAssignedAssessments: 'infoIconAssignedAssessments',
    infoIconMostAssignedAssessments: 'infoIconMostAssignedAssessments',
    infoIconLeastAssignedAssessments: 'infoIconLeastAssignedAssessments',
    infoIconMostLaunchedAssessments: 'infoIconMostLaunchedAssessments',
    infoIconLeastLaunchedAssessments: 'infoIconLeastLaunchedAssessments',
    collectionSummaryExpandButton: 'collectionSummaryExpandButton',
    collectionSummaryCollapseButton: 'collectionSummaryCollapseButton',
    assessmentSummaryExpandButton: 'assessmentSummaryExpandButton',
    assessmentSummaryCollapseButton: 'assessmentSummaryCollapseButton',
    usageSummaryExpandButton: 'usageSummaryExpandButton',
    usageSummaryCollapseButton: 'usageSummaryCollapseButton',
    mostSubscribedCollections: 'mostSubscribedCollections',
    leastSubscribedCollections: 'leastSubscribedCollections',
    mostUsedCollections: 'mostUsedCollections',
    leastUsedCollections: 'leastUsedCollections',
    mostAssignedAssessment: 'mostAssignedAssessment',
    leastAssignedAssessment: 'leastAssignedAssessment',
    mostLaunchedAssessment: 'mostLaunchedAssessment',
    leastLaunchedAssessment: 'leastLaunchedAssessment',
    collectionStatus: 'collectionStatus',
    ttsEnabled: 'ttsEnabled',
    selectDistrictsText: 'selectDistrictsText',
    searchByDistrictsText: 'searchByDistrictsText',
    districtsText: 'districtsText',
    collectionUsageReportCardDescription: 'collectionUsageReportCardDescription',
    assessmentUsageReportCardDescription: 'assessmentUsageReportCardDescription',
    searchByCollectionName: 'searchByCollectionName',
    assignmentsLabel: 'assignmentsLabel',
    studentLaunchesLabel: 'studentLaunchesLabel',
    districtsLabel: 'districtsLabel',
    subscribedCollections: 'subscribedCollections',
    publishedCollections: 'publishedCollections',
    publishedAssessments: 'publishedAssessments',
    assignedAssessments: 'assignedAssessments',
    usedCollections: 'usedCollections',
    sortedAscending: 'sortedAscending',
    sortedDescending: 'sortedDescending',
    nameText: 'nameText',
    columnOneClickableText: 'columnOneClickableText',
    columnTwoClickableText: 'columnTwoClickableText',
    columnText: 'columnText',
    questionItemAriaLabel: 'questionItemAriaLabel',
    clickable: 'clickable',
    scoreAriaLabel: 'scoreAriaLabel',
    outAriaLabel: 'outAriaLabel',
    ofAriaLabel: 'ofAriaLabel',
    percentAriaLabel: 'percentAriaLabel',
    forAriaLabel: 'forAriaLabel',
    hiddenType: 'hiddenType',
    inputTag: 'inputTag',
    selectTag: 'selectTag',
    textareaTag: 'textareaTag',
    tabindexAttribute: 'tabindexAttribute',
    notText: 'notText',
    canavasTag: 'canavasTag',
    noStudentInputText: 'noStudentInputText',
    summaryPreviewText: 'summaryPreviewText',
    avgScoreAllStandardsSchoolText: 'avgScoreAllStandardsSchoolText',
    clickAndListenBtnPressedLabel: 'clickAndListenBtnPressedLabel',
    clickAndListenBtnNotPressedLabel: 'clickAndListenBtnNotPressedLabel',
    twigAssessmentsText: 'twigAssessmentsText',
    myAssessmentsText: 'myAssessmentsText',
    sharedWithMeText: 'sharedWithMeText',
    sharedByOthersText: 'sharedByOthersText',
    editPopupDialogText: 'editPopupDialogText',
    yesButtonTextEditPopup: 'yesButtonTextEditPopup',
    editStudentInstructions: 'editStudentInstructions',
    applyChangesText: 'applyChangesText',
    editPropertiesText: 'editPropertiesText',
    questionItemInstructions: 'questionItemInstructions',
    questionItemPrefixLabel: 'questionItemPrefixLabel',
    associatedStandardsText: 'associatedStandardsText',
    resetChangesText: 'resetChangesText',
    editBtnQuestionTooltip: 'editBtnQuestionTooltip',
    editBtnQuestionGroupTooltip: 'editBtnQuestionGroupTooltip',
    editBtnQuestionItemTooltip: 'editBtnQuestionItemTooltip',
    questionGroupLabelText: 'questionGroupLabelText',
    moreOptions: 'moreOptions',
    noStandardsAppliedText: 'noStandardsAppliedText',
    allocatedPointsLabel: 'allocatedPointsLabel',
    associatedRubricLabel: 'associatedRubricLabel',
    maxPointsText: 'maxPointsText',
    optionsText: 'optionsText',
    categoriesText: 'categoriesText',
    stemsText: 'stemsText',
    choicesForDropdownText: 'choicesForDropdownText',
    optionRequiredError: 'optionRequiredError',
    categoryRequiredError: 'categoryRequiredError',
    stemRequiredError: 'stemRequiredError',
    choiceRequiredError: 'choiceRequiredError',
    xAxisTitle: 'xAxisTitle',
    yAxisTitle: 'yAxisTitle',
    minYValue: 'minYValue',
    maxYValue: 'maxYValue',
    yAxisStepSize: 'yAxisStepSize',
    xAxisInvalidText: 'xAxisInvalidText',
    yAxisInvalidText: 'yAxisInvalidText',
    tableText: 'tableText',
    minXValue: 'minXValue',
    maxXValue: 'maxXValue',
    xAxisStepSize: 'xAxisStepSize',
    confirmApplyChanges: 'confirmApplyChanges',
    confirmMessage: 'confirmMessage',
    goBackMessage: 'goBackMessage',
    confirmNoChanges: 'confirmNoChanges',
    confirmNoChangeMessage: 'confirmNoChangeMessage',
    yAxisValue: 'yAxisValue',
    labelText: 'labelText',
    xAxisValue: 'xAxisValue',
    barsLabel: 'barsLabel',
    defaultValueLabel: 'defaultValueLabel',
    interactiveLabel: 'interactiveLabel',
    snapToValuesLabel: 'snapToValuesLabel',
    chartDefaultValueTooltip: 'chartDefaultValueTooltip',
    chartSnapToValuesTooltip: 'chartSnapToValuesTooltip',
    barText: 'barText',
    collectionUsageRegion: 'collectionUsageRegion',
    selectClassesText: 'selectClassesText',
    searchByClassesText: 'searchByClassesText',
    toggleAllClassesLabelText: 'toggleAllClassesLabelText',
    classes: 'classes',
    allClassesSelected: 'allClassesSelected',
    noClassesSelected: 'noClassesSelected',
    classesSelected: 'classesSelected',
    classNameInTable: 'classNameInTable',
    avgTimeSpentText: 'avgTimeSpentText',
    byStudentsOnAssessmentText: 'byStudentsOnAssessmentText',
    onPlatformText: 'onPlatformText',
    districtAdmins: 'districtAdmins',
    schoolAdmins: 'schoolAdmins',
    districtLabel: 'districtLabel',
    classesText: 'classesText',
    date: 'date',
    allDistricts: 'allDistricts',
    teachersText: 'teachersText',
    timeSpentOnAssessment: 'timeSpentOnAssessment',
    timeSpentOnPlatform: 'timeSpentOnPlatform',
    assessmentAssignedText: 'assessmentAssignedText',
    uniqueLoginText: 'uniqueLoginText',
    totalLoginText: 'totalLoginText',
    uniqueLoginLable: 'uniqueLoginLable',
    totalLoginLable: 'totalLoginLable',
    expandOrganizationAndUserSummary: 'expandOrganizationAndUserSummary',
    collapseOrganizationAndUserSummary: 'collapseOrganizationAndUserSummary',
    organisationId: 'organisationId',
    usageText: 'usageText',
    expandUsageSummaryText: 'expandUsageSummaryText',
    collapseUsageSummaryText: 'collapseUsageSummaryText',
    itemTypesLabel: 'itemTypesLabel',
    tts: 'tts',
    enabled: 'enabled',
    trueText: 'trueText',
    roleButton: 'roleButton',
    roleLink: 'roleLink',
    isDropdownViewText: 'isDropdownViewText',
    disabledText: 'disabledText',
    collectionShortName: 'collectionShortName',
    collectionHeader: 'collectionHeader',
    statusHeader: 'statusHeader',
    districtsUsedIn: 'districtsUsedIn',
    schoolsUsedIn: 'schoolsUsedIn',
    assessmentsPublished: 'assessmentsPublished',
    assignmentsHeader: 'assignmentsHeader',
    itemTypes: 'itemTypes',
    questionItemsHeader: 'questionItemsHeader',
    rubricsHeader: 'rubricsHeader',
    performanceHeader: 'performanceHeader',
    ttsEnabledHeader: 'ttsEnabledHeader',
    collectionData: 'collectionData',
    collectionText: 'collectionText',
    statusId: 'statusId',
    statusText: 'statusText',
    publishedAssessmentCount: 'publishedAssessmentCount',
    assessmentsCount: 'assessmentsCount',
    bandwidthTitle: 'bandwidthTitle',
    performanceBandwidthText: 'performanceBandwidthText',
    isTtsEnabled: 'isTtsEnabled',
    ttsEnabledText: 'ttsEnabledText',
    districtsCount: 'districtsCount',
    schoolsCount: 'schoolsCount',
    adminAssessmentPreviewText: 'adminAssessmentPreviewText',
    timeSpentOnAssessmenttext: 'timeSpentOnAssessmenttext',
    timeSpentOnonPlatformText: 'timeSpentOnonPlatformText',
    studentPerformanceLabel: 'studentPerformanceLabel',
    progressOverviewStandardLabel: 'progressOverviewStandardLabel',
    assessmentReportsTitle: 'assessmentReportsTitle',
    classReportsTitle: 'classReportsTitle',
    gradebookReportsTitle: 'gradebookReportsTitle',
    assignmentsCount: 'assignmentsCount',
    itemTypesCount: 'itemTypesCount',
    questionItemsCount: 'questionItemsCount',
    rubricsCount: 'rubricsCount',
    browserChartTotalLoginsText: 'browserChartTotalLoginsText',
    totalLoginsPercText: 'totalLoginsPercText',
    loginOfTotal: 'loginOfTotal',
    hastagOfLogin: 'hastagOfLogin',
    infoIconTotalLoginsTextEngagementReport: 'infoIconTotalLoginsTextEngagementReport',
    assignmentsPerc: 'assignmentsPerc',
    noOfScoreTotal: 'noOfScoreTotal',
    noOfScoreObtained: 'noOfScoreObtained',
    previousMonthText: 'previousMonthText',
    nextMonthText: 'nextMonthText',
    checkedText: 'checkedText',
    unCheckedText: 'unCheckedText',
    selectDistrictText: 'selectDistrictText',
    itemTypeUsageReport: 'itemTypeUsageReport',
    itemsText: 'itemsText',
    mostUsedItems: 'mostUsedItems',
    leastUsedItems: 'leastUsedItems',
    itemUsageSummary: 'itemUsageSummary',
    assessmnetsLabel: 'assessmnetsLabel',
    lesatUsedItemTitle: 'lesatUsedItemTitle',
    mostUsedItemTitle: 'mostUsedItemTitle',
    itemTypeLabel: 'itemTypeLabel',
    selectCollectionsText: 'selectCollectionsText',
    searchByCollectionsText: 'searchByCollectionsText',
    collectionsText: 'collectionsText',
    noCollectionsSelected: 'noCollectionsSelected',
    allCollectionsSelected: 'allCollectionsSelected',
    totalCountText: 'totalCountText',
    itemTypesText: 'itemTypesText',
    totalCountTooltipText: 'totalCountTooltipText',
    usagePercText: 'usagePercText',
    usageByCollectionText: 'usageByCollectionText',
    usageByGradeText: 'usageByGradeText',
    noteUsageByGradeTab: 'noteUsageByGradeTab',
    collections: 'collections',
    collectionsSelected: 'collectionsSelected',
    notAvailableFilterForCollectionTab: 'notAvailableFilterForCollectionTab',
    notAvailableFilterForGradeTab: 'notAvailableFilterForGradeTab',
    collectionStatusLabel: 'collectionStatusLabel',
    assessmentIdText: 'assessmentIdText',
    itemTypeUsageRegion: 'itemTypeUsageRegion',
    noneText: 'noneText',
    engagementReportLoginSummary: 'engagementReportLoginSummary',
    engagementReportUsageSummary: 'engagementReportUsageSummary',
    districtsUsedAriaText: 'districtsUsedAriaText',
    schoolsUsedAriaText: 'schoolsUsedAriaText',
    itemTypeAriaText: 'itemTypeAriaText',
    districtsUsed: 'districtsUsed',
    schoolsUsed: 'schoolsUsed',
    searchByDistrictAssessmentName: 'searchByDistrictAssessmentName',
    searchBySchoolAssessmentName: 'searchBySchoolAssessmentName',
    searchByClassAssessmentName: 'searchByClassAssessmentName',
    assessmentUsageDistrictText: 'assessmentUsageDistrictText',
    assessmentUsageReportForText: 'assessmentUsageReportForText',
    assessmentUsageReportRegion: 'assessmentUsageReportRegion',
    uniqueLaunchesByStudentText: 'uniqueLaunchesByStudentText',
    totalLaunchesByStudentText: 'totalLaunchesByStudentText',
    averageTimeSpentText: 'averageTimeSpentText',
    uniqueLaunchesTooltip: 'uniqueLaunchesTooltip',
    totalLaunchesTooltip: 'totalLaunchesTooltip',
    assessmentsToPrintText: 'assessmentsToPrintText',
    assessmentsToExportCsvText: 'assessmentsToExportCsvText',
    assessmentsSelectedText: 'assessmentsSelectedText',
    averageTimeSpentTextWithTimeFormat: 'averageTimeSpentTextWithTimeFormat',
    infoIconMostlaunchedAssessmentsADistrictText: 'infoIconMostlaunchedAssessmentsADistrictText',
    infoIconMostAssignedAssessmentsADistrictText: 'infoIconMostAssignedAssessmentsADistrictText',
    infoIconMostAssignedAssessmentsASchoolText: 'infoIconMostAssignedAssessmentsASchoolText',
    allDistrictReport: 'allDistrictReport',
    aDistrictReport: 'aDistrictReport',
    aSchoolReport: 'aSchoolReport',
    itemTypeUsageReportCardDescription: 'itemTypeUsageReportCardDescription',
    gradeId: 'gradeId',
    moreReportsForCollection: 'moreReportsForCollection',
    questionItemPrefixLabelPlaceholder: 'questionItemPrefixLabelPlaceholder',
    questionGroupPrefixLabel: 'questionGroupPrefixLabel',
    gradinPreviewLabel: 'gradinPreviewLabel',
    assessmentPreview: 'assessmentPreview',
    gradingPreview: 'gradingPreview',
    previewText: 'previewText',
    previewButtonId: 'previewButtonId',
    enterTextLabel: 'enterTextLabel',
    addOptionText: 'addOptionText',
    addStemText: 'addStemText',
    addChoiceText: 'addChoiceText',
    deleteOptionText: 'deleteOptionText',
    deleteStemText: 'deleteStemText',
    deleteChoiceText: 'deleteChoiceText',
    addOptionIdString: 'addOptionIdString',
    setCorrectAnswerText: 'setCorrectAnswerText',
    singleSelectCorrectAnswerHelperText: 'singleSelectCorrectAnswerHelperText',
    disableDeleteOptionText: 'disableDeleteOptionText',
    disableDeleteStemText: 'disableDeleteStemText',
    disableDeleteChoiceText: 'disableDeleteChoiceText',
    emptyStudentInstructionText: 'emptyStudentInstructionText',
    chartLabel: 'chartLabel',
    assessmentStudentInstructionsLabel: 'assessmentStudentInstructionsLabel',
    prefixLabel: 'prefixLabel',
    suffixLabel: 'suffixLabel',
    characterLimit: 'characterLimit',
    shortTextResponseCorrectAnswer: 'shortTextResponseCorrectAnswer',
    caseSensitiveLabel: 'caseSensitiveLabel',
    createACopyPopupText: 'createACopyPopupText',
    yesButtonCreateACopyPopupText: 'yesButtonCreateACopyPopupText',
    noButtonCreateACopyPopupText: 'noButtonCreateACopyPopupText',
    createACopyDialogText: 'createACopyDialogText',
    lockAssessmentTooltipText: 'lockAssessmentTooltipText',
    correctAnswerScoringLabel: 'correctAnswerScoringLabel',
    allOrNothingLabel: 'allOrNothingLabel',
    partialWeightsLabel: 'partialWeightsLabel',
    xmlSyntaxErrorText: 'xmlSyntaxErrorText',
    correctAnswerInstructionTableInput: 'correctAnswerInstructionTableInput',
    textInputText: 'textInputText',
    dropdownText: 'dropdownText',
    manualScoredLabel: 'manualScoredLabel',
    predefinedTextLabel: 'predefinedTextLabel',
    resetButtonWarningText: 'resetButtonWarningText',
    emptyAssessmentText: 'emptyAssessmentText',
    dragAndDropCorrectAnswerHelperText: 'dragAndDropCorrectAnswerHelperText',
    editLockedAssessmentNote: 'editLockedAssessmentNote',
    editLockedAssessmentNoteOne: 'editLockedAssessmentNoteOne',
    editLockedAssessmentNoteTwo: 'editLockedAssessmentNoteTwo',
    editLockedAssessmentNoteThree: 'editLockedAssessmentNoteThree',
    lockedAssessmentWarningText: 'lockedAssessmentWarningText',
    setCorrectAnswerDisabledHelperText: 'setCorrectAnswerDisabledHelperText',
    widthError: 'widthError',
    singleSelectGridCorrectAnswerHelperText: 'singleSelectGridCorrectAnswerHelperText',
    dropDownSelectionCorrectAnswerHelperText: 'dropDownSelectionCorrectAnswerHelperText',
    genericPreviewText: 'genericPreviewText',
    customAssessmentText: 'customAssessmentText',
    customAssessmentLabel: 'customAssessmentLabel',
    pointsEntryErrorText: 'pointsEntryErrorText',
    yAxisValueTooltip: 'yAxisValueTooltip',
    correctAnswerRequiredError: 'correctAnswerRequiredError',
    exportCustomAssessmentLabel: 'exportCustomAssessmentLabel',
    districtUsedText: 'districtUsedText',
    schoolUsedText: 'schoolUsedText',
    scoringInstructionsAriaText: 'scoringInstructionsAriaText',
    studentInstructionsAriaText: 'studentInstructionsAriaText',
    noCollectionAvaliableText: 'noCollectionAvaliableText',
    acceptButtonText: 'acceptButtonText',
    alertDialogContentText: 'alertDialogContentText',
    subscriptionsAndPermissions: 'subscriptionsAndPermissions',
    newlyAddedDistrictsNote: 'newlyAddedDistrictsNote',
    editSubscriptionsButtonText: 'editSubscriptionsButtonText',
    subscriptions: 'subscriptions',
    subscriptionHistoryHeaderText: 'subscriptionHistoryHeaderText',
    editSubscriptionHeaderText: 'editSubscriptionHeaderText',
    editSingleDistrictNote: 'editSingleDistrictNote',
    editBulkDistrictsNote: 'editBulkDistrictsNote',
    collectionsSelectedText: 'collectionsSelectedText',
    openingParenthesis: 'openingParenthesis',
    closingParenthesis: 'closingParenthesis',
    partiallySelectedCollectionsNote: 'partiallySelectedCollectionsNote',
    warningPopupMsg: 'warningPopupMsg',
    proceedBtnText: 'proceedBtnText',
    subscriptionManagerText: 'subscriptionManagerText',
    editSubscriptionTooltipText: 'editSubscriptionTooltipText',
    collectionStatusDraftText: 'collectionStatusDraftText',
    viewStudentAnswersText: 'viewStudentAnswersText',
    ltiAssignmentSubmissionInstruction: 'ltiAssignmentSubmissionInstruction',
    exitAssignment: 'exitAssignment',
    exit: 'exit',
    reAttemptAsStudent: 'reAttemptAsStudent',
    standardSetId: 'standardSetId',
    removeStandardMap: 'removeStandardMap',
    betaText: 'betaText',
    betaReportMessage: 'betaReportMessage',
    districtAdmin: 'districtAdmin',
    schoolAdmin: 'schoolAdmin',
    rosteringSummary: 'rosteringSummary',
    totalEnrollments: 'totalEnrollments',
    dragAndDropCategoryCorrectAnswerHelperText: 'dragAndDropCategoryCorrectAnswerHelperText',
    clearRolloverData: 'clearRolloverData',
    clearDataText: 'clearDataText',
    districtNameText: 'districtNameText',
    confirmationPopupText: 'confirmationPopupText',
    deleteRolloverDataConfirmationPopupText: 'deleteRolloverDataConfirmationPopupText',
    studentAssignmentExportData: 'studentAssignmentExportData',
    schoolName: 'schoolName',
    adminText: 'adminText',
    sharedBy: 'sharedBy',
    sharedOn: 'sharedOn',
    sharedWith: 'sharedWith',
    viewDetails: 'viewDetails',
    SCHOOL_ADMIN: 'schoolAdmin',
    DISTRICT_OWNER: 'districtAdmin',
    TEACHER: 'teachers',
    sharedByOnText: 'sharedByOnText',
    schooladminText: 'schooladminText',
    andAdminsText: 'andAdminsText',
    disabledShareMsg: 'disabledShareMsg',
    sharePageMoreText: 'sharePageMoreText',
    assessmentStatusText: 'assessmentStatusText',
    updatedTextAssessment: 'updatedTextAssessment',
    sharedTextAssessment: 'sharedTextAssessment',
    reportHeaderText: 'reportHeaderText',
    moreOptionsForQuestion: 'moreOptionsForQuestion',
    showText: 'showText',
    assessmentsCenterDashboard: 'assessmentsCenterDashboard',
    editAssignment: 'editAssignment',
    reportsDashboard: 'reportsDashboard',
    classMonitoringReport: 'classMonitoringReport',
    viewRubricsFor: 'viewRubricsFor',
    editAssessmentContentText: 'editAssessmentContentText',
    studentDashboard: 'studentDashboard',
    viewResultsText: 'viewResultsText',
    collectionListing: 'collectionListing',
    questionListing: 'questionListing',
    questionPreview: 'questionPreview',
    groupListing: 'groupListing',
    editGroup: 'editGroup',
    editQuestionText: 'editQuestionText',
    groupPreview: 'groupPreview',
    rubricListing: 'rubricListing',
    editRubric: 'editRubric',
    assessmentListing: 'assessmentListing',
    editAssessmentText: 'editAssessmentText',
    createAssessmentText: 'createAssessmentText',
    editPerformanceBandwidth: 'editPerformanceBandwidth',
    assessmentGradingPreview: 'assessmentGradingPreview',
    assessmentPlayer: 'assessmentPlayer',
    absentIcon: 'absentIcon',
    inPorgressIcon: 'inPorgressIcon',
    submittedIcon: 'submittedIcon',
    notStartedIcon: 'notStartedIcon',
    filtersOptionsText: 'filtersOptionsText',
    settingText: 'settingText',
    settingsDescription: 'settingsDescription',
    usedInAssessment: 'usedInAssessment',
    setPerformanceBands: 'setPerformanceBands',
    editPerformanceBands: 'editPerformanceBands',
    editPerformanceLabel: 'editPerformanceLabel',
    resetPerformanceLabel: 'resetPerformanceLabel',
    editPerformanceTitle: 'editPerformanceTitle',
    resetPerformanceTitle: 'resetPerformanceTitle',
    resetConfirmationPromptMsg: 'resetConfirmationPromptMsg',
    resetAuthorVersionConfirmationPromptMsg: 'resetAuthorVersionConfirmationPromptMsg',
    resetSourceDeletedConfirmationPromptMsg: 'resetSourceDeletedConfirmationPromptMsg',
    assessmentCollections: 'assessmentCollections',
    assessmentCollection: 'assessmentCollection',
    changePerformanceBandwidth: 'changePerformanceBandwidth',
    changeBandwidthPrompt: 'changeBandwidthPrompt',
    resetCompleted: 'resetCompleted',
    bandwidthChanged: 'bandwidthChanged',
    addBands: 'addBands',
    bandwidthCollapseButton: 'bandwidthCollapseButton',
    bandwidthExpandButton: 'bandwidthExpandButton',
    assessmentCollapseButton: 'assessmentCollapseButton',
    assessmentExpandButton: 'assessmentExpandButton',
    disableDeleteBandText: 'disableDeleteBandText',
    disableDeleteBandwidthText: 'disableDeleteBandwidthText',
    noteForTeacher: 'noteForTeacher',
    noteForAdmin: 'noteForAdmin',
    managePerformanceBandwidthTitle: 'managePerformanceBandwidthTitle',
    managePerformanceBandwidthDescription: 'managePerformanceBandwidthDescription',
    assessmentSharingPermissionForTeacherTitle: 'assessmentSharingPermissionForTeacherTitle',
    assessmentSharingPermissionForTeacherDescription: 'assessmentSharingPermissionForTeacherDescription',
    twigAssessmentTitleText: 'twigAssessmentTitleText',
    moreReportNavigationLinksText: 'moreReportNavigationLinksText',
    linkText: 'linkText',
    assessmentsLibraryFilterText: 'assessmentsLibraryFilterText',
    questionBankFilterText: 'questionBankFilterText',
    questionGroupFilterText: 'questionGroupFilterText',
    assessmentListFilterText: 'assessmentListFilterText',
    schoolComparisonFilterText: 'schoolComparisonFilterText',
    schoolPerformanceFilterText: 'schoolPerformanceFilterText',
    standardsMasteryForAllSchoolsFilterText: 'standardsMasteryForAllSchoolsFilterText',
    assessmentOverviewFilterText: 'assessmentOverviewFilterText',
    assessmentReportFilterText: 'assessmentReportFilterText',
    itemAnalysisAssessmentFilterText: 'itemAnalysisAssessmentFilterText',
    assessmentUsageFilterText: 'assessmentUsageFilterText',
    progressReportForAClassFilterText: 'progressReportForAClassFilterText',
    progressReportForAStudentFilterText: 'progressReportForAStudentFilterText',
    progressReportForALLStudentFilterText: 'progressReportForALLStudentFilterText',
    collectionUsageFilterText: 'collectionUsageFilterText',
    assessmentGradebookFilterText: 'assessmentGradebookFilterText',
    gradebookReportFilterText: 'gradebookReportFilterText',
    standardsGradebookFilterText: 'standardsGradebookFilterText',
    itemTypeUsageFilterText: 'itemTypeUsageFilterText',
    liveClassMonitoringFilterText: 'liveClassMonitoringFilterText',
    performanceReportFilterText: 'performanceReportFilterText',
    progressOverviewForAStandardFilterText: 'progressOverviewForAStandardFilterText',
    standardsMasteryForAClassFilterText: 'standardsMasteryForAClassFilterText',
    standardsMasteryForAStudentInAssignmentFilterText: 'standardsMasteryForAStudentInAssignmentFilterText',
    standardsMasteryForAllClassFilterText: 'standardsMasteryForAllClassFilterText',
    standardsMasteryForAssignmentFilterText: 'standardsMasteryForAssignmentFilterText',
    standardsMasteryForAStudentFilterText: 'standardsMasteryForAStudentFilterText',
    subscriptionsFilterText: 'subscriptionsFilterText',
    assignAssessmentStepperStep1Description: 'assignAssessmentStepperStep1Description',
    assignAssessmentStepperStep2Description: 'assignAssessmentStepperStep2Description',
    liveClassMonitoringReport: 'liveClassMonitoringReport',
    chooseDateAndTime: 'chooseDateAndTime',
    resultsAvailable: 'resultsAvailable',
    dialogAlertText: 'dialogAlertText',
    countStageAssignments: 'countStageAssignments',
    countStageAssignment: 'countStageAssignment',
    resultAvailable: 'resultAvailable',
    progressText: 'progressText',
    stepText: 'stepText',
    ofText: 'ofText',
    completedText: 'completedText',
    dateDropdownLabel: 'dateDropdownLabel',
    schoolDropdownLabel: 'schoolDropdownLabel',
    assessmentDropdownLabel: 'assessmentDropdownLabel',
    studentDropdownLabel: 'studentDropdownLabel',
    classDropdownLabel: 'classDropdownLabel',
    cancelChanges: 'cancelChanges',
    applyChanges: 'applyChanges',
    clearAllFilters: 'clearAllFilters',
    collectionThumbnailImageAriaLabel: 'collectionThumbnailImageAriaLabel',
    enterPointsForQuesAriaLabel: 'enterPointsForQuesAriaLabel',
    enterPointsForQuesAriaLabelForStudent: 'enterPointsForQuesAriaLabelForStudent',
    enterPointsForQuesGroupAriaLabel: 'enterPointsForQuesGroupAriaLabel',
    enterPointsForQuesGroupAriaLabelForStudent: 'enterPointsForQuesGroupAriaLabelForStudent',
    enterPointsForQuesItemAriaLabel: 'enterPointsForQuesItemAriaLabel',
    enterPointsForQuesItemAriaLabelForStudent: 'enterPointsForQuesItemAriaLabelForStudent',
    quesRubricSolvingAriaLabel: 'quesRubricSolvingAriaLabel',
    quesRubricSolvingAriaLabelWithCriteria: 'quesRubricSolvingAriaLabelWithCriteria',
    quesRubricSolvingAriaLabelForStudent: 'quesRubricSolvingAriaLabelForStudent',
    quesRubricSolvingAriaLabelForStudentWithCriteria: 'quesRubricSolvingAriaLabelForStudentWithCriteria',
    quesRubricSolvingSelectedAriaLabel: 'quesRubricSolvingSelectedAriaLabel',
    quesRubricSolvingSelectedAriaLabelForStudent: 'quesRubricSolvingSelectedAriaLabelForStudent',
    logoutText: 'logoutText',
    logoutAriaLabelText: 'logoutAriaLabelText',
    applyChangesAnnounceMessageText: 'applyChangesAnnounceMessageText',
    applyChangesAnnounceMessageTextForTeacher: 'applyChangesAnnounceMessageTextForTeacher',
    clearAllFiltersAnnounceMessageText: 'clearAllFiltersAnnounceMessageText',
    reportButtonAnnouncement: 'reportButtonAnnouncement',
    sharedSearchPlaceHolderTeacher: 'sharedSearchPlaceHolderTeacher',
    shareAssessmentPopupTitle: 'shareAssessmentPopupTitle',
    shareUpdateAssessmentPopupTitle: 'shareUpdateAssessmentPopupTitle',
    leavePagePopupTitle: 'leavePagePopupTitle',
    editPopupDialogTitle: 'editPopupDialogTitle',
    assessmentSharingPermissionPopUP: 'assessmentSharingPermissionPopUP',
    assessmentSharingPermissionPopUpUpdate: 'assessmentSharingPermissionPopUpUpdate',
    enableForAllSchools: 'enableForAllSchools',
    selectedSchool: 'selectedSchool',
    sharePermissionTitle: 'sharePermissionTitle',
    updatePermissionTitle: 'updatePermissionTitle',
    viewDetailPopUpTitle: 'viewDetailPopUpTitle',
    sharedTeachers: 'sharedTeachers',
    settingAnnouncement: 'settingAnnouncement',
    subPopupTextDA: 'subPopupTextDA',
    subPopupTextDADate: 'subPopupTextDADate',
    subPopupText: 'subPopupText',
    subPopupTitle: 'subPopupTitle',
    errorText: 'errorText',
    errorTextForCondition: 'errorTextForCondition',
    errorTextFirefox: 'errorTextFirefox',
    editAssignmentPopupTitle: 'editAssignmentPopupTitle',
    shareAssignmentPopupTitle: 'shareAssignmentPopupTitle',
    shareUpdateAssignmentPopupTitle: 'shareUpdateAssignmentPopupTitle',
    editConfirmationPromptTitle: 'editConfirmationPromptTitle',
    resetButtonWarningTitle: 'resetButtonWarningTitle',
    assignmentCreationTitle: 'assignmentCreationTitle',
    dateTimeWarningTitle: 'dateTimeWarningTitle',
    editAssessmentCopyWarningTitle: 'editAssessmentCopyWarningTitle',
    deleteAssessmentPopupTitle: 'deleteAssessmentPopupTitle',
    newVersionCreationPopupTitle: 'newVersionCreationPopupTitle',
    linkCopiedPopupTitle: 'linkCopiedPopupTitle',
    deletePerformanceBandwidthPopupTitle: 'deletePerformanceBandwidthPopupTitle',
    deleteRubricPopupTitle: 'deleteRubricPopupTitle',
    deleteQuestionPopoupTitle: 'deleteQuestionPopoupTitle',
    deleteQuestionGroupPopupTitle: 'deleteQuestionGroupPopupTitle',
    deleteAssignmentPopupTitle: 'deleteAssignmentPopupTitle',
    pauseAssignmentPopupTitle: 'pauseAssignmentPopupTitle',
    endAssignmentPopupTitle: 'endAssignmentPopupTitle',
    startAssignmentPopupTitle: 'startAssignmentPopupTitle',
    resumeAssignmentPopupTitle: 'resumeAssignmentPopupTitle',
    resetPerformanceBandwidthPopupTitle: 'resetPerformanceBandwidthPopupTitle',
    createACopyPopupTitle: 'createACopyPopupTitle',
    reopenWarningPopupTitle: 'reopenWarningPopupTitle',
    allChangesLostPopupTitle: 'allChangesLostPopupTitle',
    assignmentNotAssignedPopupTitle: 'assignmentNotAssignedPopupTitle',
    assignmentNotAvailablePopupTitle: 'assignmentNotAvailablePopupTitle',
    assignmentAlreadySubmittedPopupTitle: 'assignmentAlreadySubmittedPopupTitle',
    assignmentNotYetAvailablePopupTitle: 'assignmentNotYetAvailablePopupTitle',
    submitConfirmationPopupTitle: 'submitConfirmationPopupTitle',
    assignmentEndedPopupTitle: 'assignmentEndedPopupTitle',
    assignmentPausedPopupTitle: 'assignmentPausedPopupTitle',
    applyChangesPopupTitle: 'applyChangesPopupTitle',
    dragHandleText: 'dragHandleText',
    dragHandleOptionText: 'dragHandleOptionText',
    allStudentCheckboxText: 'allStudentCheckboxText',
    dropDownCustomOption: 'dropDownCustomOption',
    standardsMasteryforAssignmentReport: 'standardsMasteryforAssignmentReport',
    showStandardsPageTitle: 'showStandardsPageTitle',
    subNotStarted: 'subNotStarted',
    accessDenied: 'accessDenied',
    subYetToStart: 'subYetToStart',
};

export default keys;
