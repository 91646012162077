/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const Correct = ({ size }) => (
    <svg
        role="img"
        aria-label="Correct"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        data-name="Group 11 Copy 9"
        viewBox="0 0 32 32"
    >
        <rect width="32" height="32" fill="#0db43f" rx="16" />
        <g transform="translate(6.4 6.4)">
            <path fill="none" d="M0 0h19.2v19.2H0z" />
            <path
                fill="#fff"
                d="M4.327 8.86L1.082 5.349 0 6.519 4.327 11.2 13.6 1.17 12.518 0z"
                data-name="Path"
                transform="translate(3.2 4)"
            />
        </g>
    </svg>
);

Correct.propTypes = {
    size: PropTypes.number.isRequired,
};

export default Correct;
