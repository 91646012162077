import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MuiTooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import styles from './Tooltip.style';

class Tooltip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

    onOpen = () => {
        const { onOpen } = this.props;
        this.setState({ open: true }, () => {
            if (onOpen) {
                onOpen();
            }
            window.addEventListener('keydown', this.handleEscape);
        });
    }

    onClose = () => {
        const { onClose } = this.props;
        this.setState({ open: false }, () => {
            if (onClose) {
                onClose();
            }
            window.removeEventListener('keydown', this.handleEscape);
        });
    }

    handleEscape = (event) => {
        if (event.keyCode === 27) this.onClose();
    }

    render() {
        const {
            classes, className, maxWidth, capitalizeText, open: openProp, showTooltip, isShowTooltipPresent, ...other
        } = this.props;
        const container = document.fullscreenElement ||
            document.getElementById(window.fullscreenElement) ||
            document.body;
        const { open } = this.state;
        const { title } = other;
        const flag = isShowTooltipPresent ? showTooltip && (openProp || open) : openProp || open;

        return (
            <MuiTooltip
                {...other}
                open={flag}
                onOpen={this.onOpen}
                onClose={this.onClose}
                title={flag && title}
                disableFocusListener
                classes={{
                    tooltip: clsx(
                        flag ? classes.tooltip : classes.hideTooltip,
                        classes[`tooltipWidth${this.capitalize(String(maxWidth))}`],
                        capitalizeText && classes.capitalizeText,
                        className,
                    ),
                }}
                PopperProps={{
                    container,
                }}
            />
        );
    }
}

Tooltip.propTypes = {
    maxWidth: PropTypes.oneOf(['md', 'sm', false]),
    classes: PropTypes.shape(PropTypes.any),
    className: PropTypes.string,
    showTooltip: PropTypes.bool,
    isShowTooltipPresent: PropTypes.bool,
};

Tooltip.defaultProps = {
    maxWidth: 'md',
    classes: undefined,
    className: undefined,
    showTooltip: false,
    isShowTooltipPresent: false,
};

export default withStyles(styles)(Tooltip);
