import { getUserToken, deleteAccessToken } from '../tokenHelper/accessToken';
import {
    deleteRefreshToken, clearLocalStorage,
} from '../tokenHelper';
import loginUrl from '../../apps/Login/urls';
import { destroyRefreshToken } from '../authentication';
import ssoLoginUrl from '../../apps/Authentication/urls';
import { userManager } from '../../apps/Authentication';

const logourRequestURL = `${window.config.TWIG_SCIENCE_LOGOUT_URL}`;

const logoutRequest = async () => {
    try {
        const logoutURL = new URL(logourRequestURL);
        const { id_token: idToken } = await userManager.getUser();
        logoutURL.searchParams.append('client_id', `${window.config.SSO_CLIENT_ID}`);
        logoutURL.searchParams.append(
            'post_logout_redirect_uri', `${window.config.SSO_LOGOUT_CALLBACK_URL}`,
        );
        logoutURL.searchParams.append('state', idToken);
        logoutURL.searchParams.append('federated', null);
        return logoutURL;
    } catch (error) {
        return error;
    }
};

const verifyUserAccess = async () => {
    if (getUserToken()) {
        deleteAccessToken();
        deleteRefreshToken();
        clearLocalStorage();
        return window.location.assign(`${loginUrl}?next=${window.location.pathname}`);
    }
    return true;
};

const destroySession = async () => {
    try {
        await destroyRefreshToken();
    } catch (error) {
        console.log(error);
    }

    try {
        return await verifyUserAccess();
    } catch (error) {
        console.log(error);
    }

    return false;
};

const redirect = async () => {
    if (await destroySession()) {
        return window.location.assign(`${ssoLoginUrl}?next=${window.location.pathname}`);
    }

    return window.location.assign(`${loginUrl}?next=${window.location.pathname}`);
};

export default logoutRequest;
export { verifyUserAccess, redirect };
